<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <slot name="headerStart">
          <ion-button color="danger" @click="close"><ion-icon :icon="closeOutline"></ion-icon> </ion-button>
        </slot>
      </ion-buttons>
      <ion-title>{{ props.modalTitle }}</ion-title>
      <ion-buttons slot="start">
        <slot name="headerEnd"></slot>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <slot name="headerBelow"></slot>
  <ion-content class="ion-padding">
    <slot></slot>
  </ion-content>
  <slot name="footer"></slot>
</template>

<script setup>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButtons,
  IonButton,
  IonIcon
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import { defineComponent, defineEmits, defineProps } from "vue";

defineComponent({
  name: "BaseModal",
});
const props = defineProps(["modalTitle"]);
const emit = defineEmits(["close"]);

async function close() {
  emit("close");
  await modalController.dismiss();
}
</script>
