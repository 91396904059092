<template>
    <page-wrapper>
    
        <ion-header>
            <ion-row>
                <ion-col size="1.5"><b>Table Name</b></ion-col>
                <ion-col size="1.5"><b>Record ID</b></ion-col>
                <ion-col size="4"><b>Changes</b></ion-col>
                <ion-col size="1"><b>Status</b></ion-col>
                <ion-col size="2"><b>Created At</b></ion-col>
                <ion-col size="1"><b>Approve</b></ion-col>
            </ion-row>
        </ion-header>
        <ion-content>
            <ion-row v-for="item in patch_logs" :key="item.record_id">
                <ion-col size="1.5">{{ item.table_name }}</ion-col>
                <ion-col size="1.5">
                    {{ item.record_id }}
                    <pre>
                        {{ item.relatedRecord }}
                    </pre>
                </ion-col>
                <ion-col size="4">
                    <div style="padding: 10px; padding-bottom: 0px; margin: 10px 0; margin-top: 0px; background: #333; text-align: center;" v-for="(change, key) in parseChanges(item.changes)" :key="key">
                        <b style="text-align: center;">{{ key }}</b><br/>
                        <ion-row>
                            <ion-col>
                                <p><b>OLD</b></p>
                                <p>{{ change.old }}</p>
                            </ion-col>
                            <ion-col>
                                <p><b>NEW</b></p>
                                <p>{{ change.new }}</p>
                            </ion-col>
                        </ion-row>
                    </div>
                </ion-col>
                <ion-col size="1">{{ item.status }}</ion-col>
                <ion-col size="2">{{ new Date(item.created_at).toLocaleString() }}</ion-col>
                <ion-col size="1"><ion-button v-on:click="approveChanges(item)">Approve Changes</ion-button></ion-col>
            </ion-row>
        </ion-content>
    
    </page-wrapper>
</template>

<script>
// import { ref, onMounted, computed, watch } from "vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import { api } from "@/utils/axios";
import {
  IonHeader,
  IonRow,
  IonContent,
  IonCol,
  IonButton
} from "@ionic/vue";
export default {
    components: {
        IonHeader,
        IonRow,
        IonContent,
        IonCol,
        IonButton,
        PageWrapper
    },
    data() {
        return {
            patch_logs: [],
        }
    },
    async mounted() {
        try {
            const response = await api.get('/api/review-park-changes');
            this.patch_logs = response.data.patch_logs;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        parseChanges(changesString) {
            try {
                return JSON.parse(changesString);
            } catch (error) {
                console.error('Error parsing changes:', error);
                return {};
            }
        },
        approveChanges: function(item) {
            api.post('/api/approve-park-changes', {
                item_id: item.id
            }).then(async results => {
                if(results.data.success === true) {
                   const response = await api.get('/api/review-park-changes');
                    this.patch_logs = response.data.patch_logs;
                }
            })
        }
    }
}
</script>
