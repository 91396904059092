<template>
    <page-wrapper v-if="park">
        <ion-header>
            <ion-toolbar class="header-toolbar">
                <ion-buttons slot="start">
                <ion-back-button
                    defaultHref="/ballparks"
                    color="dark"
                ></ion-back-button>
                </ion-buttons>
                <ion-title>{{ park.name }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <iframe
            width="100%"
            height="250"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            id="gmap_canvas"
            :src="`https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%${park.address}+()&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
        ></iframe>
        <div class="header-text">
            <div>
                <h1 class="text-center">{{ park.name }}</h1>
                <h5 class="text-center">{{ park.address }}</h5>
                <div class="text-center">
                    <ion-button @click="copyToClipboard">Copy Address</ion-button>
                </div>
            </div>
        
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>
                                <ion-item class="header-item">
                                    General Info
                                </ion-item>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-row>
                                <ion-col size="3">
                                    <p><b>Park Name</b></p>
                                </ion-col>
                                <ion-col>
                                    <p>{{ park.name }}</p>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col size="3">
                                    <p><b>Park Address</b></p>
                                </ion-col>
                                <ion-col>
                                    <p>{{ park.address }}</p>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col size="3">
                                    <p><b>Park City</b></p>
                                </ion-col>
                                <ion-col>
                                    <p>{{ park.city }}</p>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col size="3">
                                    <p><b>Directions to the Park</b></p>
                                </ion-col>
                                <ion-col>
                                    <p>{{ park.directions }}</p>
                                </ion-col>
                            </ion-row>                   
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
            
            <ion-row v-if="showAds">
                <ion-col>
                    <div id="parkPage1"></div>
                </ion-col>
            </ion-row>
            
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>
                                <ion-item class="header-item">
                                    Parking
                                </ion-item>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-item 
                                v-for="(parking, key) of computedParking"
                                :key="key">
                                <ion-label>
                                    <h3>{{ parking.name }}</h3>
                                </ion-label>
                                <ion-button
                                    v-for="(vote, key) of parking.vote_options"
                                    :key="key"
                                    fill="outline"
                                    @click="voteOnFeature(vote)"
                                    :color="vote.option === 'Upvote' ? 'success' : vote.option === 'Downvote' ? 'danger' : 'secondary'">
                                    <template v-if="vote.option === 'Upvote'">
                                        <ion-icon :icon="thumbsUpSharp"></ion-icon>
                                    </template>
                                    <template v-else-if="vote.option === 'Downvote'">
                                        <ion-icon :icon="thumbsDownSharp"></ion-icon>
                                    </template>
                                    <template v-else>
                                        {{ vote.option }}
                                    </template>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{{ vote.count }}
                                </ion-button>
                            </ion-item>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
            <ion-row v-if="showAds">
                <ion-col>
                    <div id="parkPage2"></div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>
                                <ion-item class="header-item">
                                    Washrooms
                                </ion-item>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-item 
                                v-for="(washroom, key) of computedWashrooms"
                                :key="key">
                                <ion-label>
                                    <h3>{{ washroom.name }}</h3>
                                </ion-label>
                                <ion-button
                                    v-for="(vote, key) of washroom.vote_options"
                                    :key="key"
                                    fill="outline"
                                    @click="voteOnFeature(vote)"
                                    :color="vote.option === 'Upvote' ? 'success' : vote.option === 'Downvote' ? 'danger' : 'secondary'">
                                    <template v-if="vote.option === 'Upvote'">
                                        <ion-icon :icon="thumbsUpSharp"></ion-icon>
                                    </template>
                                    <template v-else-if="vote.option === 'Downvote'">
                                        <ion-icon :icon="thumbsDownSharp"></ion-icon>
                                    </template>
                                    <template v-else>
                                        {{ vote.option }}
                                    </template>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{{ vote.count }}
                                </ion-button>
                            </ion-item>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
            <ion-row v-if="showAds">
                <ion-col>
                    <div id="parkPage3"></div>
                </ion-col>
            </ion-row> 
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>
                                <ion-item class="header-item">
                                    Park Amenities
                                </ion-item>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-item 
                                v-for="(amenity, key) of computedAmenities"
                                :key="key">
                                <ion-label>
                                    <h3>{{ amenity.name }}</h3>
                                </ion-label>
                                <ion-button
                                    v-for="(vote, key) of amenity.vote_options"
                                    :key="key"
                                    fill="outline"
                                    @click="voteOnFeature(vote)"
                                    :color="vote.option === 'Upvote' ? 'success' : vote.option === 'Downvote' ? 'danger' : 'secondary'">
                                    <template v-if="vote.option === 'Upvote'">
                                        <ion-icon :icon="thumbsUpSharp"></ion-icon>
                                    </template>
                                    <template v-else-if="vote.option === 'Downvote'">
                                        <ion-icon :icon="thumbsDownSharp"></ion-icon>
                                    </template>
                                    <template v-else>
                                        {{ vote.option }}
                                    </template>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{{ vote.count }}
                                </ion-button>
                            </ion-item>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
            <ion-row v-if="showAds">
                <ion-col>
                    <div id="parkPage4"></div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>
                                <ion-item class="header-item">
                                    Diamonds
                                </ion-item>
                            </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>

                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
            <ion-row v-if="showAds">
                <ion-col>
                    <div id="parkPage5"></div>
                </ion-col>
            </ion-row>
        </div>
    </page-wrapper>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import { useHead } from "@unhead/vue";
import { api } from "@/utils/axios";
import { useRoute } from "vue-router";
import { showToast } from "@/utils/useIonicComponents.js";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

import { thumbsUpSharp, thumbsDownSharp } from "ionicons/icons";

const route = useRoute();
import {
//   IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
//   IonContent,
  IonButton,
  IonRow,
  IonIcon,
  IonCol,
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonBackButton,
  IonItem,
  IonButtons,
  IonLabel
//   IonInput,
//   IonLabel,
//   IonAccordionGroup,
//   IonAccordion,
//   IonCheckbox,
//   IonSelect,
//   IonSelectOption,
//   IonTextarea,
//   IonIcon,
//   IonGrid,
} from "@ionic/vue";

const park = ref(null);
const showAds = ref(true);


const parkDetails = ref([
    {
        name: 'Has street parking nearby?',
        type: 'parking',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    }, 
    {
        name: 'Does the park have a parking lot? What is the size of the lot?',
        type: 'parking',
        vote_options: [{
            vote_option: 'Small',
            vote_count: 15
        },
        {
            vote_option: 'Medium',
            vote_count: 9
        },
        {
            vote_option: 'Large',
            vote_count: 9
        }, {
            vote_option: 'No',
            vote_count: 9
        }]
    },
    {
        name: 'Has a dedicated facility?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Facility always open?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Facility clean?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Dedicated change rooms?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Does the facility have portapotties?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are the portapotties well kept/cleaned?',
        type: 'washrooms',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are there picnic tables?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Is there a covered pavillion?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are there food vendors nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are there vending machines nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Is there a playground nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Is there a dog park nearby?',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Is there a skate park nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are there hotels nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    },
    {
        name: 'Are there restaurants nearby?',
        type: 'amenities',
        vote_options: [{
            vote_option: 'Upvote',
            vote_count: 15
        },
        {
            vote_option: 'Downvote',
            vote_count: 9
        }]
    }
]);

const computedParking = computed(() => parkDetails.value.filter(detail => detail.type === 'parking'));
const computedWashrooms = computed(() => parkDetails.value.filter(detail => detail.type === 'washrooms'));
const computedAmenities = computed(() => parkDetails.value.filter(detail => detail.type === 'amenities'));


onMounted(async () => {
      await loadParkDetails();
    //     setTimeout(() => {
    //     showAds.value = true;
    // }, 1000);
});

const loadParkDetails = async () => {
    try {
        const response = await api.get(`/api/parks/${route.params.slug}`);
        console.log('park details', response.data.park)
        park.value = response.data.park;
        parkDetails.value = response.data.park.park_features;
        useHead({
            title: `${park.value.name} - Slo-Pitch Central`,
            meta: [
            {
                name: "description",
                content: `Find out more about ${park.value.name} located at ${park.value.address}. Get directions, view amenities, and explore nearby attractions.`,
            },
            {
                name: "keywords",
                content: `${park.value.name}, Slo-Pitch Central, Ballparks, Directions, Amenities`,
            },
            {
                name: "og:title",
                content: `${park.value.name} - Slo-Pitch Central`,
            },
            {
                name: "og:image",
                content:
                "https://cdn.blacktiecollab.com/slopitchcentral/balldiamonddirectory.png",
            },
            {
                name: "og:description",
                content: `Find out more about ${park.value.name} located at ${park.value.address}. Get directions, view amenities, and explore nearby attractions.`,
            },
            {
                name: "og:type",
                content: "website",
            },
            {
                name: "og:url",
                content: `https://slopitchcentral.com/ballparks/${route.params.slug}`,
            },
            {
                name: "og:site_name",
                content: "Slo-Pitch Central",
            },
            {
                name: "og:locale",
                content: "en_US",
            },
            ],
        });
  
    } catch (error) {
        console.error(error);
    }
};

const copyToClipboard = async () => {
    const copyText = park.value.address;

    try {
        await navigator.clipboard.writeText(copyText);
        console.log("Copied to clipboard");

        showToast("Copied the address to your clipboard", "success", 6000);
    } catch (err) {
        console.log("Failed to copy text: ", err);
    }
};

const voteOnFeature = async (vote) => {
    console.log('voteOnFeature', vote);
    
    api.post('/api/parks/submit-vote', {
        vote: vote
    })

    loadParkDetails()

    showToast("Thank you for your feedback!", "success", 6000);
};

</script>

<style scoped>
.header-text {
  max-width: 800px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.header-item {
  --background: transparent;
  --border: 0px;
  --padding: 0px;
  --margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}
</style>