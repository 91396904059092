<template>
  <base-modal modal-title="Create New League/Tournament" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <h1>If you simply want to see a schedule you do not need to create an event. Go to the Find Event button instead.</h1>
    <ion-list>
      <ion-item>
        <ion-label position="stacked">Type of Event</ion-label>
        <ion-select v-model="league.type">
          <ion-select-option value="league">League</ion-select-option>
          <ion-select-option value="tournament">Tournament</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Organization</ion-label>
        <ion-select v-model="league.organization">
          <ion-select-option value="SPN"
            >SPN (except Ontario)</ion-select-option
          >
          <ion-select-option value="SPO"
            >PlaySlopitch.com (Ontario SPN + SPO)</ion-select-option
          >
          <ion-select-option value="Other">Other</ion-select-option>
        </ion-select>
      </ion-item>
      <div v-if="league.organization === 'SPN'" style="padding: 20px">
        <p>
          SPN Leagues and Tournaments will have automatic features to beef up
          your event site! This includes:
        </p>
        <p>
          - Ability to import your Teams, Divisions and other league details
        </p>
        <p>- Automatically created links for SPN Rulebook and Bat Policy</p>
        <p>
          - Additional free features like Bracket Display and first access to
          beta features
        </p>
      </div>
      <div v-if="league.organization === 'SPO'" style="padding: 20px">
        <p>
          PlaySlopitch.com Leagues and Tournaments will have automatic features
          to beef up your event site! This includes:
        </p>
        <p>- Automatically created links for Rulebook and Bat Policy</p>
        <p>
          - Additional free features like Bracket Display and first access to
          beta features
        </p>
      </div>

      <ion-item>
        <ion-label position="stacked">Name</ion-label>
        <ion-input
          :value="league.name"
          @ion-input="updateName"
          :placeholder="`Name of this ${league.type}`"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Shortcode</ion-label>
        <ion-input
          placeholder="Something like MLB, for Major League Baseball"
          :value="league.shortcode"
          @ion-input="updateShortcode"
        ></ion-input>
      </ion-item>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Points for Win</ion-label>
            <ion-input
              placeholder="Points for Win"
              v-model="league.points_for_win"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Points for Loss</ion-label>
            <ion-input
              placeholder="Points for Loss"
              v-model="league.points_for_loss"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Points for Tie</ion-label>
            <ion-input
              placeholder="Points for Tie"
              v-model="league.points_for_tie"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-item>
        <ion-label position="stacked">City</ion-label>
        <v-select
          placeholder="Select City"
          :options="[...cities.map((city) => `${city[0]} ${city[1]}`)]"
          :clearable="false"
          append-to-body
          style="width: 100%"
          :calculate-position="withPopper"
          v-model="league.city"
        >
          <template #open-indicator="{ attributes }">
            <ion-icon
              :icon="caretDownOutline"
              style="font-size: 0.8rem"
              v-bind="attributes"
            ></ion-icon>
          </template>
          <template v-slot:no-options="{ search }">
            <div v-on:click="newCity(search)">
              Sorry, City not found, Create <b>{{ search }}</b
              >?
            </div>
          </template>
        </v-select>
      </ion-item>
    </ion-list>
    <template #footer>
      <ion-button expand="full" @click="saveleague"
        >CREATE NEW {{ league.type.toUpperCase() }}</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  // IonGrid,
  IonLabel,
  // IonCard,
  // IonCardContent,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  modalController,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, helpers } from "@vuelidate/validators";
import { debounce } from "lodash";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import cities from "./cities.json";
import { useRouter } from "vue-router";

defineComponent({
  name: "NewEventModal",
});
const router = useRouter();
const { withAsync, withMessage } = helpers;
const league = ref({
  type: "league",
  points_for_win: 2,
  points_for_loss: 0,
  points_for_tie: 1,
  organization: "", // Add this lin
});

const rules = {
  name: {
    required: withMessage("Event Name Required", required),
    uniqueCheck: withMessage(
      "Unique Event Name Required",
      withAsync(uniqueEventNameCheck)
    ),
  },
  type: { required: withMessage("Event Type Required", required) },
  shortcode: {
    required: withMessage("Event Shortcode Required", required),
    uniqueCheck: withMessage(
      "Unique Event Shortcode Required",
      withAsync(uniqueShortCodeCheck)
    ),
  },
  points_for_win: {
    required: withMessage("Points for win Required", required),
    numeric: withMessage("Points for win must be numeric", numeric),
  },
  points_for_loss: {
    required: withMessage("Points for loss Required", required),
    numeric: withMessage("Points for loss must be numeric", numeric),
  },
  points_for_tie: {
    required: withMessage("Points for tie Required", required),
    numeric: withMessage("Points for tie must be numeric", numeric),
  },
  city: {
    required: withMessage("City Required", required),
  },
};
async function uniqueEventNameCheck() {
  if (league.value.name) {
    const result = await api.post("/api/check-event-name", {
      name: league.value.name,
    });
    return !result.data.exists;
  }
  return false;
}
async function uniqueShortCodeCheck() {
  if (league.value.shortcode) {
    const result = await api.post("/api/check-event-shortcode", {
      shortcode: league.value.shortcode.toUpperCase(),
    });
    return !result.data.exists;
  }
  return false;
}
function updateName(ev) {
  debounce(() => {
    const name = ev.detail.target.value;
    league.value.name = name;
    league.value.shortcode = name
      .toUpperCase()
      .replace(/[^A-Z0-9]+/gi, "")
      .substring(0, 20);
  }, 1000)();
}
function updateShortcode(ev) {
  debounce(() => {
    league.value.shortcode = ev.detail.target.value;
  }, 1000)();
}

const v$ = useVuelidate(rules, league);

async function saveleague() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveleagueRequest();
  } else {
    showToast(v$.value.$errors[0].$message, "danger", 6000);
  }
}

async function saveleagueRequest() {
  const loading = await showLoading(
    `Creating ${league.value.type}. Please Wait...`
  );
  const result = await api.post("/api/create-event", {
    name: league.value.name,
    type: league.value.type,
    shortcode: league.value.shortcode.toUpperCase(),
    points_for_win: league.value.points_for_win,
    points_for_loss: league.value.points_for_loss,
    points_for_tie: league.value.points_for_tie,
    show_home_away: league.value.show_home_away,
    deposit_amount: league.value.deposit_amount,
    city: league.value.city,
    organization: league.value.organization,
  });
  loading.dismiss();

  if (result.status === 201) {
    showToast(
      `${league.value.type}: ${league.value.name} successfully created.`,
      "success",
      6000
    );
    await close(true);
    setTimeout(() => {
      console.log(result.data.type, result.data.slug);
      router.push(`/${result.data.type}/${result.data.slug}/schedule`);
    }, 600);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close(success = false) {
  await modalController.dismiss(success);
}

async function newCity(name) {
  cities.push([name, ""]);
  league.value.city = name;
}

function withPopper(dropdownList, component) {
  dropdownList.style.width = "20rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>
<style scoped>
.details-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}

.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */
</style>
