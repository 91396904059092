<template>
  <menu-item-only-component
    title="Share Link"
    :iosIcon="shareSocialOutline"
    :mdIcon="shareSocialSharp"
    @click="copyLink"
    color="primary"
    iconColor="dark"
    v-if="route.params.leagueSlug"
  />
  <span v-if="route.name !== 'home'">
    <menu-item-only-component
      title="Home"
      :iosIcon="homeOutline"
      :mdIcon="homeSharp"
      @click="navigateTo('/')"
    />
  </span>
  <span v-if="!route.params.leagueSlug">
    <menu-item-only-component
      title="Find an Event"
      :iosIcon="searchCircleOutline"
      :mdIcon="searchCircleSharp"
      href="#find"
    />
    <menu-item-only-component
      title="Major Events"
      :iosIcon="starOutline"
      :mdIcon="starSharp"
      href="/major-events"
    />
    <menu-item-only-component
      title="Featured Events"
      :iosIcon="starOutline"
      :mdIcon="starSharp"
      href="#featured"
    />
    <menu-item-only-component
      title="Ballpark Directory"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/ballparks"
    />
    <menu-item-only-component
      title="Demos"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/demos"
    />
    <menu-item-only-component
      title="Blog"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/posts"
    />
    <menu-item-only-component
      title="FAQ"
      :iosIcon="helpCircleOutline"
      :mdIcon="helpCircleSharp"
      href="#faq"
    />
    <menu-item-only-component
      title="Contact"
      :iosIcon="mailOutline"
      :mdIcon="mailSharp"
      href="#contact"
    />
  </span>

  <!-- <menu-item-only-component
    title="My Account"
    :iosIcon="personOutline"
    :mdIcon="personSharp"
    @click="navigateTo('/account')"
    v-if="authStore.isLoggedIn && route.path !== '/account'"
  /> -->
  <!-- <div class="hr">Games</div> -->
  <menu-item-only-component
    title="Add Games"
    :iosIcon="addOutline"
    :mdIcon="addSharp"
    @click="addGame"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Import Your Schedule"
    :iosIcon="cloudUploadOutline"
    :mdIcon="cloudUploadSharp"
    @click="uploadCSV"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Toggle Games Layout"
    :iosIcon="toggleOutline"
    :mdIcon="toggleSharp"
    @click="toggleGamesLayout"
    v-if="!authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Filter Schedule"
    :iosIcon="filterCircleOutline"
    :mdIcon="filterCircleSharp"
    @click="openFilters"
    v-if="!authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Download Schedule PDF"
    :iosIcon="isGeneratingPDF ? syncCircleOutline : cloudDownloadOutline"
    :mdIcon="isGeneratingPDF ? syncCircleOutline : cloudDownloadSharp"
    @click="generatePDF"
    :disabled="isGeneratingPDF"
    :spinner="isGeneratingPDF"
    v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Download Schedule CSV"
    :iosIcon="cloudDownloadOutline"
    :mdIcon="cloudDownloadSharp"
    @click="downloadCSV"
    v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Download Results PDF"
    :iosIcon="isGeneratingPDF ? syncCircleOutline : cloudDownloadOutline"
    :mdIcon="isGeneratingPDF ? syncCircleOutline : cloudDownloadSharp"
    @click="generateResultsPDF"
    :disabled="isGeneratingPDF"
    :spinner="isGeneratingPDF"
    v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
  />
 
  <!-- <div class="hr" v-if="authStore.isConvenor && route.params.leagueSlug">
    Announcements
  </div> -->
  <!-- <menu-item-only-component
    title="Add Bracket"
    :iosIcon="gitNetworkOutline"
    :mdIcon="gitNetworkSharp"
    @click="addBracket"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  /> -->
  <!-- <menu-item-only-component
    title="Add Announcements"
    :iosIcon="megaphoneOutline"
    :mdIcon="megaphoneSharp"
    @click="addAnnouncement"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  /> -->
  <!-- <div class="hr" v-if="authStore.isLoggedIn">Events</div> -->

  <menu-item-only-component
    title="Create New League/Tourney"
    :iosIcon="addCircleOutline"
    :mdIcon="addCircleSharp"
    @click="openNewEventModal"
    v-if="authStore.isLoggedIn"
  />
  <menu-item-only-component
      title="How to Use Demonstrations"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/demos"
    />
</template>
<script setup>
import { defineComponent, ref } from "vue";
import MenuItemOnlyComponent from "@/components/drawer/MenuItemOnlyComponent.vue";
// import { menuController } from "@ionic/vue";
import {
  addOutline,
  addSharp,
  // filterOutline,
  // filterSharp,
  cloudDownloadOutline,
  cloudDownloadSharp,
  cloudUploadOutline,
  cloudUploadSharp,
  shareSocialOutline,
  shareSocialSharp,
  homeOutline,
  homeSharp,
  // megaphoneOutline,
  // megaphoneSharp,
  toggleOutline,
  toggleSharp,
  searchCircleOutline,
  searchCircleSharp,
  starOutline,
  starSharp,
  listCircleOutline,
  listCircleSharp,
  helpCircleOutline,
  helpCircleSharp,
  mailOutline,
  mailSharp,
  addCircleOutline,
  addCircleSharp,
  syncCircleOutline,
  filterCircleSharp,
  filterCircleOutline
  // gitNetworkOutline,
  // gitNetworkSharp,
} from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
import { useAuthStore } from "@/stores/authStore";
import { useRoute, useRouter } from "vue-router";
import { openModal, showToast } from "@/utils/useIonicComponents.js";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import FilterModal from "@/components/schedule/filters/FilterModal.vue";
// import NewAnnouncementModal from "@/components/announcements/NewAnnouncementModal.vue";
import NewEventModal from "@/components/event/NewEventModal.vue";
// import AddBracketModal from "@/components/brackets/AddBracketModal.vue";
import LeagueSwitchModal from "@/components/LeagueSwitchModal.vue";

import { usePreferenceStore } from "@/stores/preferenceStore";

const isGeneratingPDF = ref(false);

defineComponent({
  name: "SideMenu",
});
const router = useRouter();
const route = useRoute();
const preferenceStore = usePreferenceStore();

const authStore = useAuthStore();
const gameStore = useGameStore();

async function navigateTo(location) {
  // await menuController.close();
  router.push(location);
}

async function addGame() {
  await openModal(AddGameModal, "addGameModal");
}
// async function addBracket() {
//   await openModal(AddBracketModal, "addBracketModal");
// }
// async function addAnnouncement() {
//   await openModal(NewAnnouncementModal, "newAnnouncementModal");
// }
async function openFilters() {
  await openModal(FilterModal, "filterModal");
}

async function openNewEventModal() {
  const response = await openModal(NewEventModal, "newEventModal");
  if (response === true) {
    setTimeout(() => {
      openUserEventsModal();
    }, 600);
  }
}
async function openUserEventsModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "SwitchLeague",
    },
  });
  setTimeout(() => {
    openModal(LeagueSwitchModal, "LeagueSwitchModal");
  }, 1);
}

async function generatePDF() {
  isGeneratingPDF.value = true;
  await gameStore.downloadPDF();
  isGeneratingPDF.value = false;
}

async function generateResultsPDF() {
  isGeneratingPDF.value = true;
  await gameStore.downloadResultsPDF();
  isGeneratingPDF.value = false;
}


function downloadCSV() {
  gameStore.downloadCSV();
}

// function downloadPDF(callback) {
//   gameStore.downloadPDF();

//   if (callback && typeof callback === 'function') {
//     callback();
//   }
// }

async function uploadCSV() {
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}

async function copyLink() {
  await navigator.clipboard.writeText(window.location.href);
  showToast("Link Copied", "primary", 1000);
}

function toggleGamesLayout() {
  const currentLayout = preferenceStore.getGamesLayout;
  const nextLayout = currentLayout === "grid" ? "table" : (currentLayout === "table" ? "brackets" : "grid");
  preferenceStore.setGamesLayout(nextLayout);
}


</script>

<style scoped>
.hr {
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.4;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}
</style>
