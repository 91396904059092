<template>
  <page-wrapper>
    <sitewide-notice />
    <ion-header>
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Blog Posts</ion-title>
      </ion-toolbar>
    </ion-header>
    <card-wrapper
      cardTitle="Our Blog"
      id="featured"
      cardSubtitle="Interesting information on a variety of Slo-Pitch topics."
    >
      <ion-row class="ion-no-padding">
        <template v-for="(item, index) in postsWithAds" :key="index">
          <template v-if="item.isAd">
            <ion-col :key="'ad-' + index" size="12">
              <div :id="`postsPage${Math.floor(index / 5) + 1}`"></div>
            </ion-col>
          </template>
          <template v-else>
            <ion-col size-sm="4" size="12">
              <ion-card class="ion-no-padding ion-no-margin">
                <img
                  :src="item.jetpack_featured_media_url"
                  :alt="formattedTitle(item?.title?.rendered) + ' Title Image'"
                />
                <ion-card-header>
                  <ion-card-title style="text-transform: none">
                    {{ formattedTitle(item?.title?.rendered) }}
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <div class="text-excerpt" v-html="item.excerpt.rendered"></div>
                  <br />
                  <ion-button
                    size="small"
                    expand="full"
                    :router-link="`/blog/${item.slug}`"
                  >
                    Read Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </template>
        </template>
      </ion-row>
    </card-wrapper>
    <footer-component></footer-component>
  </page-wrapper>
</template>

<script setup>
import { defineComponent, ref, onMounted, computed } from "vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import FooterComponent from "@/components/landing/FooterComponent.vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import axios from "axios";
import {
  IonRow,
  IonCol,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonButton,
  onIonViewWillEnter,
} from "@ionic/vue";
import he from "he";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "PostsPage",
});

const posts = ref([]);

const postsWithAds = computed(() => {
  const result = [];
  posts.value.forEach((post, index) => {
    result.push(post);
    // After every 4 posts, insert an ad component placeholder
    if ((index + 1) % 3 === 0) {
      result.push({ isAd: true }); // This is a placeholder for ad component
    }
  });
  return result;
});

onIonViewWillEnter(async () => {
  await grabBlogPosts();
});

onMounted(async () => {
  await grabBlogPosts();
});

const formattedTitle = (title) => {
  return he.decode(title);
};

async function grabBlogPosts() {
  try {
    const results = await axios.get("https://wii.dnw.mybluehost.me/wp-json/wp/v2/posts");
    console.log("results", results);
    posts.value = results.data;
  } catch (error) {
    console.error("Error fetching blog posts:", error);
  }
}
</script>