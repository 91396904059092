<template>
  <base-modal modal-title="Filter Games" @close="close">
    <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template>
    <template #headerEnd>
      <ion-button @click="resetFilters"> Reset Filters </ion-button>
    </template>
    <filters-component :key="resetKey" />
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import FiltersComponent from "@/components/schedule/filters/FiltersComponent.vue";
import { defineComponent, ref } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import { useGameStore } from "@/stores/gameStore";
import { useRouter, useRoute } from "vue-router";
import { usePreferenceStore } from "@/stores/preferenceStore";

defineComponent({
  name: "FilterModal",
});
const preferenceStore = usePreferenceStore();
const router = useRouter();
const route = useRoute();
const resetKey = ref(0);
router.replace({
  query: {
    ...route.query,
    open_modal: "Filters",
  },
});
async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
const gameStore = useGameStore();
function resetFilters() {
  gameStore.resetFilters();
  preferenceStore.setFilters(gameStore.gameFilters);
  resetKey.value++; //so that component picks up the new values
  router.replace({
    query: {
      ...route.query,
      showReportedGames: null,
      division_id: null,
      park_name: null,
      team: null,
      timeslot: null,
      month: null,
      day: null,
    },
  });
}
</script>
