<template>
  <ion-page>
    <ion-content>
      <sitewide-notice></sitewide-notice>
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Budget Generator</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="main-content">
        <card-wrapper
          cardTitle="Budget Generator"
          id="featured"
          cardSubtitle="Generate a budget for your league or tournament so that you can see how much you have to charge to run your event."
        >
          <ion-row>
            <ion-col size-sm="6" size="12">
              <h2 class="ion-text-center">THE BASICS</h2>
              <ion-item color="light">
                <ion-label position="stacked">League or Tournament</ion-label>
                <ion-select v-model="leagueOrTournament">
                  <ion-select-option value="league">League</ion-select-option>
                  <ion-select-option value="tournament"
                    >Tournament</ion-select-option
                  >
                </ion-select>
              </ion-item>
              <ion-item color="light">
                <ion-label position="stacked"> Number of Teams </ion-label>
                <ion-input v-model="numberOfTeams" type="number"> </ion-input>
              </ion-item>
              <ion-item color="light">
                <ion-label position="stacked">
                  Number of Games (Per Team)
                </ion-label>
                <ion-input v-model="numberOfGames" type="number"> </ion-input>
              </ion-item>
            </ion-col>
            <ion-col size-sm="6" size="12">
              <h2 class="ion-text-center">UMPIRES</h2>
              <ion-item color="light">
                <ion-label position="stacked"
                  >Cost Per Umpire Per Game ($)</ion-label
                >
                <ion-input v-model="costPerUmpirePerGame"></ion-input>
              </ion-item>
              <ion-item color="light">
                <ion-label position="stacked"
                  >Number of Umpires Per Game</ion-label
                >
                <ion-input v-model="numberOfUmpiresPerGame"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col size-sm="6" size="12">
              <h2 class="ion-text-center">FIELDS</h2>
              <ion-item color="light">
                <ion-label position="stacked">Payment Format</ion-label>
                <ion-select v-model="paymentFormat">
                  <ion-select-option value="per-game"
                    >Per Game (or Hourly)</ion-select-option
                  >
                  <ion-select-option value="flat-rate"
                    >Flat Rate</ion-select-option
                  >
                  <ion-select-option value="per-team"
                    >Per Team</ion-select-option
                  >
                </ion-select>
              </ion-item>
              <ion-item color="light" v-if="paymentFormat === 'per-game'">
                <ion-label position="stacked"
                  >Cost Per Field Per Game ($)</ion-label
                >
                <ion-input v-model="costPerFieldPerGame"></ion-input>
                <ion-note style="font-size: 12px"
                  >Please note that this is per game. If games are 1.5 hours and
                  you pay hourly, please adjust the amount.</ion-note
                >
              </ion-item>
              <ion-item color="light" v-if="paymentFormat === 'flat-rate'">
                <ion-label position="stacked">Flat Rate Cost ($)</ion-label>
                <ion-input v-model="costPerFieldFlatRate"></ion-input>
                <ion-note style="font-size: 12px"
                  >Please enter the flat rate for all diamonds
                  combined.</ion-note
                >
              </ion-item>
              <ion-item color="light" v-if="paymentFormat === 'per-team'">
                <ion-label position="stacked">Per Team Cost ($)</ion-label>
                <ion-input v-model="costOfFieldsPerTeam"></ion-input>
                <ion-note style="font-size: 12px"
                  >Please enter the flat rate for all diamonds
                  combined.</ion-note
                >
              </ion-item>
            </ion-col>
            <ion-col size-sm="6" size="12">
              <h2 class="ion-text-center">BALLS</h2>
              <ion-item color="light">
                <ion-label position="stacked"
                  >Cost Per Dozen Balls ($)</ion-label
                >
                <ion-input v-model="costPerDozenBalls"></ion-input>
              </ion-item>

              <ion-item color="light">
                <ion-label position="stacked"
                  >Number of New Balls Per Game</ion-label
                >
                <ion-input v-model="numberOfNewBallsPerGame"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <!-- BASICS -->
          <!-- league or tournament -->
          <!-- number of teams -->

          <!-- UMPIRES -->
          <!-- cost per umpire per game -->
          <!-- new balls per game -->
          <!-- number of umpires per game -->
          <!-- FIELDS -->
          <!-- field type payments -->
          <!-- add your field and field prices and hours allocated -->
          <!-- do lights cost extra -->
        </card-wrapper>
        <card-wrapper
          cardTitle="PNL Sheet"
          cardSubtitle="Here's the profit and loss sheet. Please note that this is an estimate and does not include any other costs like prizing (necessary), insurance/affiliation rates and more."
          id="featured"
        >
          <ion-item color="light">
            <ion-label position="stacked">Desired Margin</ion-label>
            <ion-input type="number" v-model="desiredMargin"></ion-input>
          </ion-item>
          <p>
            This is calculated based on your total expenses with a
            {{ desiredMargin }}% profit margin.
          </p>
          <ion-item color="primary">
            <ion-label>Our Suggested Price Per Team</ion-label>
            <ion-label slot="end">${{ suggestedPricePerTeam }}</ion-label>
          </ion-item>
          <br /><br /><br />
          <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding">
              <h2 class="ion-text-center">INCOME</h2>

              <ion-item color="light">
                <ion-label position="stacked"
                  >Enter Price Per Team ($)</ion-label
                >
                <ion-input type="number" v-model="pricePerTeam"></ion-input>
              </ion-item>
              <ion-item color="tertiary">
                <ion-label><b>Estimated Income</b></ion-label>
                <ion-label slot="end"
                  ><b>${{ estimatedIncome }}</b></ion-label
                >
              </ion-item>
            </ion-col>
            <ion-col class="ion-no-padding">
              <h2 class="ion-text-center">EXPENSES</h2>
              <ion-item>
                <ion-label> Estimated Umpire Cost </ion-label>
                <ion-label slot="end">${{ estimatedUmpireCost }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>Estimated Field Cost</ion-label>
                <ion-label slot="end">${{ estimatedFieldCost }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-label> Estimated Ball Cost </ion-label>
                <ion-label slot="end">${{ estimatedBallCost }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-label color="danger">
                  <b>Initial Expenses (Subtotal)</b>
                </ion-label>
                <ion-label slot="end" color="danger">
                  <b>${{ initialExpenses }}</b
                  ><!-- Total expenses  -->
                </ion-label>
              </ion-item>
              <ion-item color="light">
                <ion-label position="stacked"> Prizing Expense </ion-label>
                <ion-input type="number" v-model="prizingExpense"></ion-input>
              </ion-item>
              <ion-item color="light">
                <ion-label position="stacked">
                  Insurance/Sanctioning Expense
                </ion-label>
                <ion-input type="number" v-model="insuranceExpense"></ion-input>
              </ion-item>
              <ion-item color="danger">
                <ion-label>
                  <b>Total Expenses (Subtotal)</b>
                </ion-label>
                <ion-label slot="end">
                  <b>${{ totalExpenses }}</b
                  ><!-- Total expenses  -->
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item :color="estimatedProfits > 0 ? 'success' : 'danger'">
            <ion-label><b>Estimated Profits/Losses</b></ion-label>
            <ion-label slot="end"
              ><b>${{ estimatedProfits }}</b></ion-label
            >
          </ion-item>
        </card-wrapper>
        <card-wrapper cardTitle="Other Calculations" id="featured">
          <ion-item>
            <ion-label slot="end">{{ totalNumberOfGames }} games</ion-label>
            <ion-label>Total Number of Games</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Total Number of Balls</ion-label>
            <ion-label slot="end">{{ totalNumberOfBalls }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Number of Dozens to Order</ion-label>
            <ion-label slot="end">{{ numberOfDozensToOrder }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Balls Left Over</ion-label>
            <ion-label slot="end">{{ numberOfBallsLeftOver }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Cost Per Team</ion-label>
            <ion-label slot="end">${{ costPerTeam }}</ion-label>
          </ion-item>
          <!-- cost per team -->
        </card-wrapper>
        <card-wrapper
          cardTitle="Export Your Calculations"
          id="featured"
          cardSubtitle="Register your account to save your calculations and export them to a spreadsheet."
        >
          <ion-button v-if="authStore.isLoggedIn" @click="exportCSV"
            >Save & Export</ion-button
          >
          <ion-button v-else @click="openRegisterModal"
            >Register and Export</ion-button
          >
        </card-wrapper>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent, ref, computed, watch } from "vue";
import * as XLSX from "xlsx";
import { useHead } from "@unhead/vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import { useAuthStore } from "@/stores/authStore";
import RegisterModal from "@/components/register/RegisterModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";

// const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
const authStore = useAuthStore();

const leagueOrTournament = ref("league");
const numberOfTeams = ref(4);
const numberOfGames = ref(20);
const costPerUmpirePerGame = ref(35);
const numberOfUmpiresPerGame = ref(1);
const costPerDozenBalls = ref(80);
const numberOfNewBallsPerGame = ref(2);
const costPerFieldPerGame = ref(35);
const costPerFieldFlatRate = ref(200);
const costOfFieldsPerTeam = ref(75);
const paymentFormat = ref("per-game");
const pricePerTeam = ref(1600);
const prizingExpense = ref(0);
const insuranceExpense = ref(0);
const desiredMargin = ref(30);

const totalNumberOfGames = computed(() => {
  return (numberOfTeams.value * numberOfGames.value) / 2;
});

const estimatedUmpireCost = computed(() => {
  return (
    ((numberOfTeams.value * numberOfGames.value) / 2) *
    costPerUmpirePerGame.value *
    numberOfUmpiresPerGame.value
  );
});

const totalNumberOfBalls = computed(() => {
  return totalNumberOfGames.value * numberOfNewBallsPerGame.value;
});

const numberOfDozensToOrder = computed(() => {
  return Math.ceil(totalNumberOfBalls.value / 12);
});

const estimatedBallCost = computed(() => {
  return numberOfDozensToOrder.value * costPerDozenBalls.value;
});

const numberOfBallsLeftOver = computed(() => {
  return numberOfDozensToOrder.value * 12 - totalNumberOfBalls.value;
});

const estimatedFieldCost = computed(() => {
  if (paymentFormat.value === "per-game") {
    return (
      ((numberOfTeams.value * numberOfGames.value) / 2) *
      costPerFieldPerGame.value
    );
  }
  if (paymentFormat.value === "per-team") {
    return numberOfTeams.value * costOfFieldsPerTeam.value;
  }
  if (paymentFormat.value === "flat-rate") {
    return costPerFieldFlatRate.value;
  }
  return 0;
});

const initialExpenses = computed(() => {
  return (
    estimatedUmpireCost.value +
    estimatedBallCost.value +
    estimatedFieldCost.value
  );
});

const suggestedPricePerTeam = computed(() => {
  const rawPrice =
    (totalExpenses.value / numberOfTeams.value) *
    (1 + desiredMargin.value / 100);
  return roundToNearest(rawPrice, 50); // Or use 100 if you want to round to the nearest 100
});

const estimatedIncome = computed(() => {
  return numberOfTeams.value * pricePerTeam.value;
});

const estimatedProfits = computed(() => {
  return roundToTwoDigits(estimatedIncome.value - initialExpenses.value);
});

const totalExpenses = computed(() => {
  return (
    roundToTwoDigits(initialExpenses.value) +
    roundToTwoDigits(prizingExpense.value) +
    roundToTwoDigits(insuranceExpense.value)
  );
});

const roundToTwoDigits = (value) => {
  return Math.round(value * 100) / 100;
};

const roundToNearest = (value, nearest) => {
  return Math.round(value / nearest) * nearest;
};

const costPerTeam = computed(() => {
  return roundToTwoDigits(totalExpenses.value / numberOfTeams.value);
});

watch(leagueOrTournament, (newValue) => {
  if (newValue === "league") {
    numberOfGames.value = 20;
    pricePerTeam.value = 1800;
  } else if (newValue === "tournament") {
    numberOfGames.value = 4;
    pricePerTeam.value = 300;
  }
});
// const numberOfPlayoffGames = ref(4)

console.log("leagueOrTournament", leagueOrTournament);

defineComponent({
  name: "BudgetGeneratorPage",
});

useHead({
  title: "League/Tournament Budget Generator - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content: "Calculate your league or tournament budget with our easy-to-use budget generator. Estimate your income, expenses, cost per team, and profit margin. Also, find out how many softballs you need to order.",
    },
    {
      name: "keywords",
      content: "Budget, Generator, League, Tournament, Slo-Pitch Central, Income, Expenses, Cost, Team, Profit Margin, Softballs",
    },
    {
      name: "og:title",
      content: "League/Tournament Budget Generator - Slo-Pitch Central",
    },
    {
      name: "og:description",
      content: "Use our budget generator to calculate your league or tournament budget. Estimate your income, expenses, cost per team, and profit margin. Also, find out how many softballs you need to order.",
    },
    {
      name: "og:image",
      content: "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/budget-generator",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
    {
      name: "og:author",
      content: "Slo-Pitch Central - Unleash Your League or Tournament Here",
    },
  ],
});

async function openRegisterModal() {
  const response = await openModal(RegisterModal, "registerModal");
  if (response === true) {
    exportCSV();
  }
}

function exportCSV() {
  //to export the budget
  // Create a workbook
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Profit and Loss Estimator",
    Subject: "Budget Generator",
    Author: "Your Name",
  };

  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet([
    { "League or Tournament": leagueOrTournament.value },
    { "Number of Teams": numberOfTeams.value },
    { "Number of Games": numberOfGames.value },
    { "Cost Per Umpire Per Game": costPerUmpirePerGame.value },
    { "Number of Umpires Per Game": numberOfUmpiresPerGame.value },
    { "Cost Per Dozen Balls": costPerDozenBalls.value },
    { "Number of New Balls Per Game": numberOfNewBallsPerGame.value },
    { "Cost Per Field Per Game": costPerFieldPerGame.value },
    { "Cost Per Field Flat Rate": costPerFieldFlatRate.value },
    { "Cost Of Fields Per Team": costOfFieldsPerTeam.value },
    { "Payment Format": paymentFormat.value },
    { "Price Per Team": pricePerTeam.value },
    { "Prizing Expense": prizingExpense.value },
    { "Insurance Expense": insuranceExpense.value },
    { "Desired Margin": desiredMargin.value + "%" },
    { "Total Number Of Games": totalNumberOfGames },
    { "Estimated Umpire Cost": estimatedUmpireCost },
    { "Total Number Of Balls": totalNumberOfBalls },
    { "Number of Dozens To Order": numberOfDozensToOrder },
    { "Estimated Ball Cost": estimatedBallCost },
    { "Number of Balls Left Over": numberOfBallsLeftOver },
    { "Estimated Field Cost": estimatedFieldCost },
    { "Initial Expenses": initialExpenses },
    { "Suggested Price Per Team": suggestedPricePerTeam },
    { "Estimated Income": estimatedIncome },
    { "Estimated Profits": estimatedProfits },
    { "Total Expenses": totalExpenses },
    { "Cost Per Team": costPerTeam },
    // Add all your data here
  ]);

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write workbook and trigger download
  XLSX.writeFile(wb, "eventbudget.xlsx");

  // alert("Export CSV triggered");
}
</script>

<style scoped>
.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

ion-card-title {
  font-size: 18px;
}
</style>
