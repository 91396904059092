<template>
  <ion-page>
    <span style="margin-top: 2.5rem"></span>
    <span v-if="gameStore.games.length > 0">
      <date-nav-toolbar />
      <select-games-toolbar
        v-if="authStore.isConvenor && showDesktopMode"
        @resetScores="resetAllEditedScore"
      />
    </span>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 15%;
        background-position: center;
        background-size: cover;
      "
      :style="{
        'background-image': `url('${leagueStore.leagueBackground}')`,
      }"
    ></div>
    <div
      style="
        display: flex;
        width: 100%;
        height: 100%;
        background-color: var(--ion-background-color);
      "
    >
      <div style="flex: 100%">
        <ion-content
          :style="{
            '--keyboard-offset': keyboardHeight + 'px',
            '--background': '#00000000',
          }"
        >
          <span v-if="preferenceStore.getGamesLayout === 'grid'">
            <scheduled-games-grid-all
              :key="resetScore"
              v-if="
                gameStore.games.length > 0 &&
                gameStore.currentShownDate === 'all'
              "
            />
            <scheduled-games-grid
              :key="resetScore"
              v-if="
                gameStore.games.length > 0 &&
                gameStore.currentShownDate !== 'all'
              "
            />
          </span>
          <span v-if="preferenceStore.getGamesLayout === 'table'">
            <scheduled-games-table-all
              :key="resetScore"
              v-if="
                gameStore.games.length > 0 &&
                gameStore.currentShownDate === 'all'
              "
            />
            <scheduled-games-table
              :key="resetScore"
              v-if="
                gameStore.games.length > 0 &&
                gameStore.currentShownDate !== 'all'
              "
            />
          </span>
          <span v-if="preferenceStore.getGamesLayout === 'brackets'">
            <brackets-page></brackets-page>
          </span>
          <no-data-message v-if="gameStore.games.length === 0">
            No games scheduled... yet!
          </no-data-message>
          <div class="padding-bottom"></div>
        </ion-content>
      </div>
    </div>
    <!-- <filter-fab v-if="!reportGames.showReportButton && !showDesktopMode" /> -->
    <edit-schedule-fab
      v-if="authStore.isConvenor && !reportGames.showReportButton"
      @resetScores="resetAllEditedScore"
    />
    <!-- <div style="display: flex; width: 100%">
      <ion-button
        style="width: 100%"
        expand="full"
        v-if="reportGames.showReportButton"
        @click="sendReportRequest"
        class="report-score-button"
        ><ion-icon slot="start" :icon="checkmarkDoneOutline" />Report
        Score</ion-button
      >
      <ion-button
        style="width: 100%"
        v-if="reportGames.showReportButton"
        @click="resetAllEditedScore"
        expand="full"
        color="danger"
        class="report-score-button"
        ><ion-icon slot="start" :icon="refreshCircleOutline" />Undo All Score
        Changes</ion-button
      >
    </div> -->
  </ion-page>
</template>

<script setup>
import { defineComponent, onBeforeUnmount, ref } from "vue";
import {
  IonPage,
  IonContent,
  // IonButton,
  // IonIcon,
  useKeyboard,
} from "@ionic/vue";

import BracketsPage from "./BracketsPage.vue";
// import { checkmarkDoneOutline, refreshCircleOutline } from "ionicons/icons";
import ScheduledGamesGrid from "@/components/schedule/game-grid/ScheduledGamesGrid.vue";
import ScheduledGamesGridAll from "@/components/schedule/game-grid/ScheduledGamesGridAll.vue";
import ScheduledGamesTable from "@/components/schedule/game-table/ScheduledGamesTable.vue";
import ScheduledGamesTableAll from "@/components/schedule/game-table/ScheduledGamesTableAll.vue";
import DateNavToolbar from "../components/schedule/DateNavToolbar.vue";
import SelectGamesToolbar from "../components/schedule/SelectGamesToolbar.vue";
import EditScheduleFab from "../components/schedule/EditScheduleFab.vue";
import NoDataMessage from "@/components/layouts/NoDataMessage.vue";
// import FilterFab from "../components/schedule/FilterFab.vue";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
// import FiltersComponent from "@/components/schedule/filters/FiltersComponent.vue";
import { onBeforeRouteUpdate } from "vue-router";
import { debounce } from "lodash";

defineComponent({
  name: "SchedulePage",
});
const gameStore = useGameStore();
const authStore = useAuthStore();
const reportGames = useReportGameStore();
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
// const router = useRouter();
// const route = useRoute();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

const resetScore = ref(0); //the key rerenders component which resets any edit
const filterComponentKey = ref(0); //to reset filtercomponent when rest button is pressed
const { keyboardHeight } = useKeyboard();

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  //to empty out reported games when the page is navigated away
  reportGames.games = [];
  window.removeEventListener("resize", resizeHandler);
});
onBeforeRouteUpdate(() => {
  filterComponentKey.value++;
});

function resetAllEditedScore() {
  reportGames.games = [];
  preferenceStore.setUserReportGames();
  resetScore.value++;
  console.log("All Score Edits Cleared");
}

// async function sendReportRequest() {
//   try {
//     await reportGames.sendReportRequest();
//     resetAllEditedScore();
//   } catch (err) {
//     console.log(err);
//   }
// }
</script>
<style scoped>
.report-score-button {
  margin: 0;
  z-index: 100;
}

.padding-bottom {
  padding-bottom: 60px;
  /* this is to clear the button height */
}
</style>
