<template>
  <ion-list>
    <h1
      style="
        color: var(--ion-color-dark);
        text-align: center;
        text-transform: uppercase;
      "
    >
      <span style="background-color: var(--ion-color-primary); padding: 0 10px">
        All Events
      </span>
    </h1>
    <span v-if="authStore.isLoggedIn">
      <ion-searchbar v-model="searchTerm" placeholder="Search events" />
      <!-- <ion-list-header><ion-label>Your Events</ion-label></ion-list-header> -->
      <!-- <ion-item v-if="userEvents?.length > 5">
        <ion-searchbar v-model="searchTerm" placeholder="Search your events" />
      </ion-item> -->
      <!-- <ion-item
        button
        
        v-for="event of filteredEvents"
        :key="event.id"
        ><ion-label> {{ event.name.toUpperCase() }}</ion-label></ion-item
      > -->
      <ion-row>
        <ion-col 
          size="12"
          v-for="event in filteredEvents"
          :key="event.id"
          @click="
            $emit('eventFound', {
              slug: event.slug,
              type: event.type,
            })
          "
        >
          <div 
            class="custom-card" 
            
          >
            <div class="card-content">
              <div class="text-content">
                <h2>{{ event.name }}</h2>
                <p>{{ event.city }} - Created on {{ event.created_at }}</p>
                <!-- Add more text content here if needed -->
              </div>
              <div class="arrow-container">
                <ion-icon :icon="chevronForward" />
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-item v-if="searchTerm && filteredEvents.length === 0"
        ><ion-label>{{ searchTerm }} not found</ion-label></ion-item
      >
      <ion-item v-if="!userEvents?.length"
        ><ion-label>{{ authStore.user?.full_name }} has no Events</ion-label
        ><ion-button @click="openNewEventModal"
          >Create New League/Tournament</ion-button
        ></ion-item
      >
    </span>
    <ion-list-header v-if="preferenceStore.userShortCodes.length > 0"
      ><ion-label>Events from your teams</ion-label></ion-list-header
    >
    
    <IonItem
      button
      v-for="shortcode in preferenceStore.userShortCodes"
      :key="shortcode.shortcode"
      @click="
        emit('eventFound', {
          slug: shortcode.leagueSlug,
          type: shortcode.leagueType,
        })
      "
      ><ion-label>{{ shortcode.leagueName.toUpperCase() }}</ion-label></IonItem
    >
  </ion-list>
</template>

<script setup>
import { defineComponent, ref, defineEmits, computed, watchEffect } from "vue";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonSearchbar,
  IonList,
  IonListHeader,
  IonIcon,
  IonRow,
  IonCol
} from "@ionic/vue";
import { chevronForward } from 'ionicons/icons';
import { useAuthStore } from "@/stores/authStore";
import { api } from "@/utils/axios";
import { openModal } from "@/utils/useIonicComponents.js";
import { usePreferenceStore } from "@/stores/preferenceStore";
import NewEventModal from "@/components/event/NewEventModal.vue";

defineComponent({
  name: "UserEvents",
});
const emit = defineEmits(["userNoEvent", "eventFound"]);

const authStore = useAuthStore();
const preferenceStore = usePreferenceStore();

const userEvents = ref([]);
const loading = ref(false);
const searchTerm = ref('');

watchEffect(() => {
  if (authStore.isLoggedIn) {
    getUserEvents();
  }
});

async function getUserEvents() {
  loading.value = true;
  try {
    const results = await api.get(`/api/every-event`);
    userEvents.value = results.data.events;
    console.log('userEvents', userEvents.value);
    if (!userEvents.value.length) {
      emit("userNoEvent");
    }
  } catch (error) {
    console.error('Error fetching events:', error);
  } finally {
    loading.value = false;
  }
}

async function openNewEventModal() {
  const response = await openModal(NewEventModal, "newEventModal");
  if (response === true) {
    getUserEvents();
  }
}

const filteredEvents = computed(() => {
  if (!userEvents.value) return [];
  if (!searchTerm.value) return userEvents.value;
  
  const lowercaseSearchTerm = searchTerm.value.toLowerCase();
  return userEvents.value.filter(event => 
    event.name.toLowerCase().includes(lowercaseSearchTerm) ||
    (event.city && event.city.toLowerCase().includes(lowercaseSearchTerm)) ||
    event.slug.toLowerCase().includes(lowercaseSearchTerm)
  );
});
</script>

<style scoped>
ion-item {
  --background: #191919;
}
</style>

<style scoped>
.custom-card {
  
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #494949;
}

.custom-card:hover {
  background: #232323;
}

.card-content {
  display: flex;
  align-items: stretch;
  min-height: 80px;
}

.text-content {
  flex-grow: 1;
  padding: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content h2 {
  margin: 0 0 4px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.text-content p {
  margin: 0;
  font-size: 14px;
  text-align: left;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Fixed width */
  flex-shrink: 0; /* Prevent shrinking */
  background-color: rgba(0, 0, 0, 0.4);
}

.arrow-container ion-icon {
  font-size: 24px;
  color: white;
}

.search-button {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .search-button {
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .search-button {
    font-size: 12px;
  }
}
</style>