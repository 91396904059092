<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Event Directory</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="main-content">
        <card-wrapper
          id="find"
          cardTitle="Find an Event by Name"
          cardSubtitle="Enter the name you were given below to access the event."
        >
          <league-event-name-search />
        </card-wrapper>
        <ion-row>
          <ion-col>
            <div id="eventDirectory1"></div>
          </ion-col>
        </ion-row>
        <card-wrapper
          id="find"
          cardTitle="Find an Event by Shortcode"
          cardSubtitle="Enter the shortcode you were given below to access the event."
        >
          <league-search
            @eventFound="router.push(`/${$event.type}/${$event.slug}`)"
          />
        </card-wrapper>
        <ion-row>
          <ion-col>
            <div id="eventDirectory2"></div>
          </ion-col>
        </ion-row>
        <card-wrapper
          cardTitle="Featured Events"
          id="featured"
          cardSubtitle="Some of our most popularly viewed events."
        >
          <featured-events />
        </card-wrapper>
        <ion-row>
          <ion-col>
            <div id="eventDirectory3"></div>
          </ion-col>
        </ion-row>
        <card-wrapper
          id="find"
          cardTitle="League Directory"
          cardSubtitle="This directory includes all public leagues that are hosted on the Slo-Pitch Central platform."
        >
          <ion-row class="ion-no-padding">
            <ion-col
              size-sm="4"
              size="12"
              v-for="league of leagues"
              :key="league.id"
            >
              <ion-card class="ion-no-margin">
                <ion-card-header>
                  <ion-card-title>{{ league.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    size="small"
                    expand="full"
                    :router-link="`/league/${league.slug}/schedule`"
                  >
                    Go Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </card-wrapper>
        <ion-row>
          <ion-col>
            <div id="eventDirectory4"></div>
          </ion-col>
        </ion-row>
        <card-wrapper
          id="find"
          cardTitle="Tournament Directory"
          cardSubtitle="This directory includes all public tournaments that are hosted on the Slo-Pitch Central platform."
        >
          <ion-row class="ion-no-padding">
            <ion-col
              size-sm="4"
              size="12"
              v-for="tournament of tournaments"
              :key="tournament.id"
            >
              <ion-card class="ion-no-margin">
                <ion-card-header>
                  <ion-card-title>{{ tournament.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    size="small"
                    expand="full"
                    :router-link="`/tournament/${tournament.slug}/schedule`"
                  >
                    Go Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </card-wrapper>
        <ion-row>
          <ion-col>
            <div id="eventDirectory5"></div>
          </ion-col>
        </ion-row>
        <footer-component> </footer-component>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent, onMounted, ref } from "vue";
import { useHead } from "@unhead/vue";
import {
  IonPage,
  IonRow,
  IonCol,
  onIonViewWillEnter,
  IonContent,
  IonCard,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import SitewideNotice from "@/components/header/SiteNotice.vue";

import FooterComponent from "@/components/landing/FooterComponent.vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import LeagueSearch from "@/components/landing/LeagueSearch.vue";
import LeagueEventNameSearch from "../components/landing/LeagueEventNameSearch.vue";
import { api } from "@/utils/axios";
import FeaturedEvents from "@/components/landing/FeaturedEvents.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

const leagues = ref([]);
const tournaments = ref([]);
const router = useRouter();

// const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

defineComponent({
  name: "EventDirectoryPage",
});

useHead({
  title: "Event Directory - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content:
        "Explore various leagues and tournaments hosted on Slo-Pitch Central. Find the perfect event for you and your team.",
    },
    {
      name: "keywords",
      content: "Slo-Pitch, Leagues, Tournaments, Events, Directory",
    },
    {
      name: "og:title",
      content: "Event Directory - Slo-Pitch Central",
    },
    {
      name: "og:description",
      content:
        "Explore various leagues and tournaments hosted on Slo-Pitch Central. Find the perfect event for you and your team.",
    },
    {
      name: "og:image",
      content:
        "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/event-directory",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
  ],
});

onMounted(() => {
  getLeaguesAndTournaments();
});

onIonViewWillEnter(() => {
  getLeaguesAndTournaments();
});

const getLeaguesAndTournaments = async () => {
  const allLeagues = await api.get(`/api/all-leagues`);
  console.log("allLeagues", allLeagues);
  leagues.value = allLeagues.data.events;
  const allTournaments = await api.get(`/api/all-tournaments`);
  tournaments.value = allTournaments.data.events;
};
</script>

<style scoped>
.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

ion-card-title {
  font-size: 18px;
}
</style>
