<template>
    <base-modal modal-title="Make a Substitution">
      <template #headerStart>
        <ion-button @click="close()"> Close </ion-button>
      </template>
        <!-- add ion segments -->
        <ion-segment mode="md" v-model="selectedSegment">
            <ion-segment-button value="awayTeam">
                <ion-label>{{ scorekeepStore.awayTeam.name || 'Away Team' }}</ion-label>
            </ion-segment-button>
            <ion-segment-button value="homeTeam">
                <ion-label>{{ scorekeepStore.homeTeam.name || 'Home Team' }}</ion-label>
            </ion-segment-button>
        </ion-segment>

        <div class="lineup-title">Bench</div>
        <ion-list>
            <ion-item 
                v-for="(bench,index) of currentBench"
                :key="index">
                <ion-label style="max-width: 30px;">{{ bench.number }}</ion-label>
                <ion-label>{{ bench.name }}</ion-label>

                <ion-button 
                  slot="end"
                  v-if="!selectedBenchPlayer && bench.substitution_id === null"
                  @click="selectedBenchPlayer = bench"
                >Sub In</ion-button>
                
                <ion-button 
                  slot="end"
                  color="tertiary"
                  v-if="!selectedBenchPlayer && bench.substitution_id && bench.reentried === false"
                  @click="reenterPlayerToOriginalSpot(bench)"
                >Re-Enter</ion-button>

                <ion-button 
                  slot="end"
                  color="dark"
                  v-if="!selectedBenchPlayer && bench.substitution_id && bench.reentried === true"
                  :disabled="true"
                >Re-Entered</ion-button>
            </ion-item>
        </ion-list>
        <div class="lineup-title">Lineup</div>
        <ion-list>
            <ion-item 
                v-for="(lineup,index) of currentLineup"
                :key="index">
                <ion-label style="max-width: 30px;">{{ lineup.number }}</ion-label>
                <ion-label>{{ lineup.name }}</ion-label>
                <ion-button 
                  slot="end"
                  v-if="selectedBenchPlayer"
                  @click="makeSubstitution(lineup)"
                >Sub Out for {{ selectedBenchPlayer?.name }}</ion-button>
                <ion-button 
                  slot="end"
                  color="dark"
                  v-if="!selectedBenchPlayer && lineup.substitution_id && lineup.reentried === true"
                  :disabled="true"
                >Re-Entered</ion-button>
            </ion-item>
        </ion-list>
      <template #footer>
        <!-- <ion-button expand="full" @click="close">Dismiss</ion-button> -->
      </template>
    </base-modal>
  </template>
  
  <script setup>
  import { useScorekeepStore } from "@/stores/scorekeepStore"
  import {
    modalController,
    IonButton,
    // IonCard,
    // IonCardHeader,
    // IonCardTitle,
    // IonCardSubtitle,
    // IonCardContent,
    IonItem,
    IonList,
    IonLabel,
    IonSegment,
    IonSegmentButton
  } from "@ionic/vue";
  import { defineComponent, computed, ref } from "vue";
  import BaseModal from "@/components/layouts/BaseModal.vue";

  const selectedBenchPlayer = ref(null);
  const scorekeepStore = useScorekeepStore();
  console.log('scorekeepStore', scorekeepStore)

  const selectedSegment = ref('awayTeam');
  
  defineComponent({
    name: "AddToHomeModal",
  });

  const currentLineup = computed(() => {
    return selectedSegment.value === 'awayTeam' ? scorekeepStore.awayTeamLineup : scorekeepStore.homeTeamLineup;
  });

  const currentBench = computed(() => {
    return selectedSegment.value === 'awayTeam' ? scorekeepStore.awayTeamBench : scorekeepStore.homeTeamBench;
  });
  
  async function close() {
    await modalController.dismiss();
  }

  const makeSubstitution = async (selectedLineupPlayer) => {
    console.log('makeSubstitution', selectedLineupPlayer, selectedBenchPlayer.value, selectedSegment.value);
    scorekeepStore.makeSubstitution(selectedLineupPlayer, selectedBenchPlayer.value, selectedSegment.value);
    selectedBenchPlayer.value = null;
    await modalController.dismiss();
  }

  const reenterPlayerToOriginalSpot = async (selectedBenchPlayer) => {
    console.log('reenterPlayerToOriginalSpot', selectedBenchPlayer, selectedSegment.value);
    scorekeepStore.reenterPlayerToOriginalSpot(selectedBenchPlayer, selectedSegment.value);
    selectedBenchPlayer.value = null;
    await modalController.dismiss();
  }

  </script>
  
  <style scoped>
  .lineup-title {
      font-size: 16px;
      font-weight: 700;
      
      
      margin: 10px 0;
      margin-left: 20px;
      text-transform: uppercase;
  }
  </style>