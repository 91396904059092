<template>
    <ion-page>
      <ion-content>
        <sitewide-notice />
        <ion-header>
          <ion-toolbar class="header-toolbar">
            <ion-buttons slot="start">
              <ion-menu-button color="dark"></ion-menu-button>
            </ion-buttons>
            <ion-title>Tournaments</ion-title>
          </ion-toolbar>
        </ion-header>
        <div class="header-text">
          <div class="text-center" style="padding: 20px">
            <h1 style="text-transform: uppercase">
              Slo-Pitch Tournaments in {{ city }}
            </h1>
            <p>This is a comprehensive list of the Slo-Pitch tournaments hosted on Slo-Pitch Central based on the city they selected when creating the tournament.</p>
          </div>
        </div>
        <div class="header-text" style="margin-top: 2rem">
          <!-- <ion-searchbar
            v-model="searchQuery"
            @ionChange="search"
            placeholder="Search for a tournament"
          >
          </ion-searchbar> -->
          <ion-spinner v-if="loading"></ion-spinner>
  
          <div v-if="tournaments.length > 0" class="tournaments-grid">
            <template v-for="(tournament, index) in tournaments" :key="tournament.id">
              <ion-card class="tournament-card">
                <ion-card-header>
                  <ion-card-title>{{ tournament.name }}</ion-card-title>
                  <ion-card-subtitle>{{ tournament.city }}</ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>
                  <!-- Add more tournament details here -->
                  <ion-button :href="`/tournament/${tournament.slug}`">View Tournament Page</ion-button>
                </ion-card-content>
              </ion-card>
  
              <!-- Insert ad div after every 4 tournaments or at the end if fewer than 4 tournaments -->
              <div v-if="(index + 1) % 4 === 0 || index === tournaments.length - 1" class="ad-container" :id="`tournamentsByCityPage${Math.floor(index / 4)}`"></div>
            </template>
          </div>
          <div v-else-if="!loading">
            No tournaments found in {{ city }}.
          </div>
<!--   
          <ion-infinite-scroll @ionInfinite="loadMore($event)" threshold="100px" :disabled="!moreToLoad">
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more tournaments..."
            ></ion-infinite-scroll-content>
          </ion-infinite-scroll> -->
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import SitewideNotice from "@/components/header/SiteNotice.vue";
  import { api } from "@/utils/axios";
  import { useHead } from "@unhead/vue";
  
  import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner, IonContent, IonPage, IonButton, IonTitle, IonButtons, IonToolbar, IonHeader, IonMenuButton, IonCardContent } from "@ionic/vue";
  
  const route = useRoute();
  const city = ref(route.params.city); // Get city slug from route parameters
  
  useHead({
    title: `Slo-Pitch Tournaments in ${city.value}, Canada | Slo-Pitch Central`,
    meta: [
      {
        name: "description",
        content: `Explore the most comprehensive directory of Slo-Pitch tournaments in ${city.value} on Slo-Pitch Central. Find the perfect tournament for your next game.`,
      },
    ],
  });
  
  const searchQuery = ref("");
  const tournaments = ref([]);
  const loading = ref(false);
  const currentPage = ref(0);
  const moreToLoad = ref(true);
  
  const loadMore = async (event) => {
    if (!moreToLoad.value) {
      if (event) event.target.complete();
      return;
    }
  
    loading.value = true;
    try {
      const response = await api.get(
        `/api/tournaments-by-city?city=${city.value}&search=${searchQuery.value}&page=${currentPage.value + 1}`
      );
      if (response.data.tournaments && response.data.tournaments.length > 0) {
        tournaments.value = [...tournaments.value, ...response.data.tournaments];
        currentPage.value += 1;
      } else {
        moreToLoad.value = false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (event) event.target.complete();
      loading.value = false;
    }
  };
  
  onMounted(() => {
    loadMore();
  });
  
//   const search = async () => {
//     loading.value = true;
//     tournaments.value = [];
//     currentPage.value = 0;
//     moreToLoad.value = true;
//     await loadMore();
//   };
  </script>
  
  <style scoped>
  .header-area {
    width: 100%;
    display: block;
    position: relative !important;
  }
  
  .header-image {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(125, 56, 240, 0.6) 80%,
      rgba(125, 56, 240, 0.9) 100%
    );
    text-align: center;
  }
  
  .header-text {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .main-content {
    display: block;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .tournaments-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .tournament-card {
    width: 100%;
  }
  
  .ad-container {
    grid-column: 1 / -1;
    height: 100px; /* Adjust as needed */
    background-color: #f0f0f0; /* Placeholder background */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
  
  @media (max-width: 768px) {
    .tournaments-grid {
      grid-template-columns: 1fr;
    }
  }
  
  ion-card-title {
    font-size: 18px;
  }
  
  .tally-app {
    background: #121212 !important;
  }
  </style>