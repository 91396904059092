<template>
  <!-- <div
    style="color: var(--ion-color-dark); text-align: center"
    class="division-title"
    :style="[
      {
        backgroundColor: uniqolor(props.division.division_name, {
          lightness: [25, 30],
        }).color,
      },
    ]"
  >
    {{ props.division.division_name }}
  </div> -->
  <ion-segment @ionChange="segmentChanged($event)" v-model="selectedSegment">
    <ion-segment-button
      v-for="mode in gameTypes"
      :key="mode.value"
      :value="mode.value"
    >
      <ion-label>{{ mode.label }}</ion-label>
    </ion-segment-button>
  </ion-segment>
  <div class="overflow-table">
    <table style="width: 700px; margin-top: 1rem">
      <tr style="height: 3rem">
        <!-- <th style="width: 3%"></th> -->
        <th style="width: 30%; padding-left: 5px" class="left-align">Team</th>
        <th class="fixed-width">GP</th>
        <th class="fixed-width">W</th>
        <th class="fixed-width">L</th>
        <th class="fixed-width">T</th>
        <th class="fixed-width">P</th>
        <th class="fixed-width">RF</th>
        <th class="fixed-width">RA</th>
        <th class="fixed-width">+/-</th>
        <th v-if="authStore.isConvenor">
          <ion-icon :icon="buildOutline"></ion-icon>
        </th>
      </tr>
      <standings-rows
        v-for="(team, team_key) in filteredTeams"
        :key="team_key"
        :team="team"
        :mode="selectedSegment"
        :team_key="team_key"
      ></standings-rows>
    </table>
  </div>
  <div class="spacer"></div>
</template>
<script setup>
import { defineComponent, defineProps, computed, ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import StandingsRows from "@/components/standings/StandingsRows.vue";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import { IonIcon } from "@ionic/vue";
import { buildOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";

// import uniqolor from "uniqolor";

const selectedSegment = ref("Season"); // Default selected segment
// const modes = ["Practice", "Season", "Playoffs"];
const leagueStore = useLeagueStore();

const gameTypes = computed(() => {
  if (leagueStore.league.type === "tournament") {
    return [
      { label: "Pre-Seed", value: "Practice" },
      { label: "Round Robin", value: "Season" },
      { label: "Eliminations", value: "Playoffs" },
    ];
  } else {
    return [
      { label: "Practice", value: "Practice" },
      { label: "Season", value: "Season" },
      { label: "Playoffs", value: "Playoffs" },
    ];
  }
});

const filteredTeams = computed(() => {
  // Assuming props.division has practice_standings, season_standings, and playoffs_standings arrays
  switch (selectedSegment.value) {
    case "Practice":
      return props.division.practice_standings;
    case "Season":
      return props.division.season_standings;
    case "Playoffs":
      return props.division.playoffs_standings;
    default:
      return [];
  }
});

function segmentChanged(event) {
  selectedSegment.value = event.detail.value;
}

defineComponent({
  name: "StandingsTable",
});
const props = defineProps({ division: Object });
const authStore = useAuthStore();

console.log("division", props.division);
</script>
<style scoped>
table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
th {
  /* border-bottom: 1px solid var(--ion-color-medium); */
}

.fixed-width {
  width: 60px;
}

.left-align {
  text-align: left;
}
.division-title {
  margin-top: 4rem;
  background: #7d38f0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.spacer {
  padding-bottom: 100px;
}

.overflow-table {
  width: 100%;
  overflow-x: auto;
  z-index: 1;
}
</style>
