<template>
  <base-modal modal-title="Set Sort Order for Games" @close="close">
    <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template>
    <sort-by-component :items="sortOrder" @updateOrder="handleUpdateOrder" />
    <template #footer>
      <ion-button expand="full" @click="saveSortOrder">Save</ion-button>
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import SortByComponent from "@/components/schedule/sort-by/SortByComponent.vue";
import { defineComponent, ref } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { useGameStore } from "@/stores/gameStore";

defineComponent({
  name: "SortModal",
});
const gameStore = useGameStore();

const sortOrder = ref(gameStore.sortOrder);

const handleUpdateOrder = (newOrder) => {
  console.log("New order:", newOrder);
  sortOrder.value = newOrder; // Update the items with the new order
};

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "Sort",
  },
});
async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(gameStore.sortOrder);
}

function saveSortOrder() {
  gameStore.sortOrder = sortOrder.value;
  close();
}
</script>
