<template>
  <base-modal modal-title="Find Events">
    <template #headerStart>
      <ion-button @click="close()"> Close </ion-button>
    </template>
    <span>
      <ion-button
        @click="openNewEventModal"
        v-if="authStore.isLoggedIn"
        expand="full"
        >Create New League/Tournament</ion-button
      >
      <ion-segment mode="md" v-model="selectedSegment">
        <ion-segment-button value="name"
          >Search By Event Name</ion-segment-button
        >
        <ion-segment-button value="shortcode"
          >Search By Shortcode</ion-segment-button
        >
        <ion-segment-button value="events" v-if="authStore.isLoggedIn"
          >Your Events</ion-segment-button
        >
        <ion-segment-button value="all-events" v-if="authStore.isLoggedIn && authStore.isAdmin"
          >All Events</ion-segment-button
        >
      </ion-segment>
      <ion-list>
        <div v-if="selectedSegment === 'shortcode'">
          <league-search :twoColumns="false" @eventFound="eventFound" />
        </div>
        <div v-if="selectedSegment === 'events' && authStore.isLoggedIn">
          <user-events @eventFound="eventFound" />
        </div>
        <div v-if="selectedSegment === 'name'">
          <league-event-name-search @eventFound="eventFound">
          </league-event-name-search>
          <!-- <league-search
            :twoColumns="false"
            @eventFound="router.push(`/${$event.type}/${$event.slug}/dashboard`)"
          /> -->
        </div>
        <div v-if="selectedSegment === 'all-events' && authStore.isLoggedIn && authStore.isAdmin">
          <all-events></all-events>
          <!-- <user-events @eventFound="eventFound" /> -->
        </div>
      </ion-list>
      <div id="leagueSwitchModal"></div>
    </span>
  </base-modal>
</template>

<script setup>
import {
  IonList,
  // IonListHeader,
  // IonLabel,
  modalController,
  IonButton,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import { onMounted, defineComponent, ref } from "vue";
import LeagueSearch from "@/components/landing/LeagueSearch.vue";
import { useRouter, useRoute } from "vue-router";
import BaseModal from "@/components/layouts/BaseModal.vue";
import UserEvents from "@/components/landing/UserEvents.vue";
import AllEvents from "@/components/landing/AllEvents.vue";
import LeagueEventNameSearch from "./landing/LeagueEventNameSearch.vue";
import { useAuthStore } from "@/stores/authStore";
const authStore = useAuthStore();
import { openModal } from "@/utils/useIonicComponents.js";
import NewEventModal from "@/components/event/NewEventModal.vue";


defineComponent({
  name: "LeagueSwitchModal",
});
console.log("leagueswitchmodal open");
const router = useRouter();
const route = useRoute();
const selectedSegment = ref(authStore.isLoggedIn ? "events" : "name");

async function eventFound(ev) {
  await close();
  router.push(`/${ev.type}/${ev.slug}/dashboard`);
}

async function close() {
  console.log("leagueswitchmodal close");
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}

async function openNewEventModal() {
  await openModal(NewEventModal, "newEventModal");
  // if (response === true) {
  //   setTimeout(() => {
  //     openUserEventsModal();
  //   }, 600);
  // }
}

onMounted(() => {
  console.log(selectedSegment.value);
  // if (authStore.isAdmin) {
  //   selectedSegment.value = "events";
  // } else {
  //   selectedSegment.value = "shortcode";
  // }
});

// watch(route, async () => {
//   await close();
// });
</script>
