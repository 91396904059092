<template>
  <base-modal modal-title="Announcements">
    <template #headerStart>
      <ion-button @click="cancel"> Cancel </ion-button>
    </template>
    <template #headerEnd v-if="authStore.isConvenor">
      <ion-button @click="addNewAnnouncement"
        ><ion-icon :icon="addCircleOutline"></ion-icon> Announcement
      </ion-button>
    </template>
    <announcement-component />
  </base-modal>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonButton, IonIcon, modalController } from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import BaseModal from "@/components/layouts/BaseModal.vue";
import AnnouncementComponent from "@/components/announcements/AnnouncementComponent.vue";
import NewAnnouncementModal from "@/components/announcements/NewAnnouncementModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";
import { useAuthStore } from "@/stores/authStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useRoute, useRouter } from "vue-router";

defineComponent({
  name: "AnnouncementModal",
});
const preferenceStore = usePreferenceStore();
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "announcements",
  },
});

preferenceStore.setUserNotificationLastOpened();

async function addNewAnnouncement() {
  const response = await openModal(
    NewAnnouncementModal,
    "newAnnouncementModal"
  );
  if (response === true) {
    console.log("New Announcement Added");
  }
}

async function cancel() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
