<template>
  <ion-menu menu-id="main-menu" content-id="main-content" type="reveal">
    <ion-list
      style="
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      "
      id="menu-list"
      :style="listStyle"
    >
      <ion-list-header style="flex-shrink: 0">
        <div style="text-align: center; width: 100%">
          <img v-if="leagueStore.leagueLogo" :src="leagueStore.leagueLogo" />
          <img
            v-else
            src="https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png"
            alt="Slo-Pitch Central Logo"
          />
        </div>
      </ion-list-header>
      <div style="flex-grow: 1; overflow-y: auto">
        <div>
          <side-menu-toggle />
        </div>

        <div id="medRectAtf"></div>
      </div>

      <div style="flex-shrink: 0" v-if="authStore.isLoggedIn">
        <ion-item>
          <ion-label position="stacked">Logged in As</ion-label>
          <ion-input :disabled="true">{{ authStore.user?.email }}</ion-input>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-button
              expand="full"
              size="small"
              @click="router.push('/account')"
              fill="outline"
            >
              My Account
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              expand="full"
              size="small"
              color="danger"
              @click="authStore.logout"
              fill="outline"
            >
              Logout
            </ion-button>
          </ion-col>
        </ion-row>
      </div>

      <div style="flex-shrink: 0" v-else>
        <ion-button expand="full" @click="openLoginModal">Login</ion-button>
        <ion-button expand="full" @click="openRegisterModal"
          >Create An Account</ion-button
        >
      </div>
    </ion-list>
  </ion-menu>
</template>

<script setup>
import {
  IonMenu,
  IonList,
  IonInput,
  IonListHeader,
  IonButton,
  IonLabel,
  IonItem,
  IonRow,
  IonCol,
  // isPlatform,
} from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useAuthStore } from "@/stores/authStore";
import SideMenuToggle from "@/components/drawer/SideMenuToggle.vue";
import { openModal } from "@/utils/useIonicComponents.js";
import LoginModal from "@/components/login/LoginModal.vue";
import RegisterModal from "@/components/register/RegisterModal.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { useRoute, useRouter } from "vue-router";
import tinycolor from "tinycolor2";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "SideMenu",
});
const leagueStore = useLeagueStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
async function openLoginModal() {
  await openModal(LoginModal, "loginModal");
}
async function openRegisterModal() {
  await openModal(RegisterModal, "registerModal");
}

const listStyle = computed(() => {
  let pcolor;
  if (leagueStore.league?.primary_color && route.params.leagueSlug) {
    pcolor = tinycolor(leagueStore.league.primary_color);
  } else {
    pcolor = tinycolor("#7d38f0");
  }

  const pcolor1 = pcolor.setAlpha(0.6).toRgbString();
  const pcolor2 = pcolor.setAlpha(0.9).toRgbString();

  return `background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    ${pcolor1} 80%,
    ${pcolor2} 100%
  );`;
});
</script>

<style scoped>
img {
  margin: 0.5rem 0 0.5rem 0;
  width: 200px !important;
  max-width: unset;
  @media all and (max-width: 960px) {
    margin: 0;
    width: 150px !important;
  }
}
ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#menu-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#menu-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-list {
  background: rgb(255, 255, 255);

  text-align: center;
}
</style>
