<template>
  <base-modal
    :modal-title="`WELCOME TO ${leagueStore.leagueName.toUpperCase()}`"
  >
    <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template>
    <template #headerBelow>
      <ion-item>
        <ion-checkbox v-model="neverShowAgain"></ion-checkbox>
        <label>Never open this again</label>
      </ion-item>
    </template>
    <div style="display: flex; flex-direction: column; align-items: center">
      <img :src="leagueStore.league.logo_url || 'https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png'" style="max-width: 200px;" alt="">
      <!-- <p>THREE EASY STEPS</p> -->
      <select-favourite-teams style="width: 90%"></select-favourite-teams>
      <br/><br/>
      <coach-shortcode style="width: 90%" v-if="leagueStore.league.can_teams_report === 1"></coach-shortcode>
      <!-- <filter-favourite-teams style="width: 90%"></filter-favourite-teams> -->
      <!-- <br/><br/>
      <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
        <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
            3. TRACK ATTENDANCE?
        </span>
      </h1>
      <p style="text-align: center;">If you want to track attendance for each of your games, you can do so! We just need a little more info from you. <b>You can always come back and do this later.</b></p>
      <ion-item @click="clickToCopy" style="cursor: pointer;">
        <ion-label position="stacked">Copy and share this link to your teammates</ion-label>
        <ion-input
          style="cursor: pointer;"
          :value="generateInviteLink()"
          
          >
        </ion-input>
      </ion-item> -->

      <!-- <ion-chip color="danger"
        >YOU DO NOT NEED TO REGISTER AN ACCOUNT TO SEE YOUR SCHEDULE</ion-chip
      > -->
    </div>
    <template #footer>
      
      <ion-button expand="full" @click="confirmAndClose">Confirm</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import { IonButton, modalController, IonItem, IonCheckbox } from "@ionic/vue";
// import { IonInput, IonLabel } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
// import FilterFavouriteTeams from "@/components/schedule/welcome/FilterFavouriteTeams.vue";
import SelectFavouriteTeams from "@/components/schedule/welcome/SelectFavouriteTeams.vue";
import CoachShortcode from "@/components/schedule/welcome/CoachShortcode.vue";
// import CardWrapper from "@/components/layouts/CardWrapper.vue";
  // import { showToast } from "@/utils/useIonicComponents";

defineComponent({
  name: "WelcomeModal",
});
const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();
const neverShowAgain = ref(false);


function confirmAndClose() {
  console.log(neverShowAgain.value);
  if (neverShowAgain.value) {
    preferenceStore.setWelcomeModalsDismissed();
  }
  modalController.dismiss();
}

function close() {
  modalController.dismiss();
}

// function generateInviteLink() {
//   return 'https://slopitchcentral.com/league/test-event/roster/invite'
// }

// function clickToCopy() {
//   const link = generateInviteLink()
//   console.log('link', link)
//   navigator.clipboard.writeText(link).then(function() {
//     showToast('Link copied to clipboard', 'success', 3000);
//   }, function(err) {
//     console.error('Async: Could not copy text: ', err);
//   });
// }

</script>
