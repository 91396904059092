<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Features</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="main-content">
        <card-wrapper
          cardTitle="Our Features"
          id="featured"
          cardSubtitle="What makes us different from the rest."
        >
          <ion-row>
            <ion-col size-md="4" size="12">
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Free To Use</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    We don't charge you to use our platform. We make money
                    through advertising and sponsorships.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Easy to Use</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    We've made it easy to use. You can create your league and
                    schedule in minutes.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Optimized for Readability</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    Colour coded teams, easy to read schedules, and standings
                    allow for any user of any age to use the platform with ease.
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size-md="4" size="12">
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title
                    >Teams Can Report Their Own Scores</ion-card-title
                  >
                </ion-card-header>
                <ion-card-content>
                  <p>
                    Your teams can report their own scores. No need for complex
                    passwords or accounts. Set a shortcode for them and they
                    enter it once and it's saved forever.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Easy to Use Filters</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    Players can filter their schedule by team, division, or
                    date. Those filters persist so they don't have to do it
                    every time they open the app.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Use It How You Want To</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    We understand everyone uses these programs differently so
                    we've made it easy to use it how you want to.
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size-md="4" size="12">
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Playoff Mode</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    We have a playoff mode that allows you to set up and track
                    your playoff games.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title>Can Upload any CSV Schedule</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>
                    Using a program to schedule your games? No problem. You can
                    upload any CSV schedule and we'll parse it and display it
                    for you.
                  </p>
                </ion-card-content>
              </ion-card>
              <ion-card color="primary">
                <ion-card-header>
                  <ion-card-title
                    >Reschedule, Postpone Games & Announcements</ion-card-title
                  >
                </ion-card-header>
                <ion-card-content>
                  <p>
                    You can reschedule games, postpone games, and make
                    announcements to your players about these things quickly.
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </card-wrapper>
        <card-wrapper
          cardTitle="Contact Us"
          id="contact"
          cardSubtitle="Send us a message and we'll respond ASAP."
        >
          <contact-us />
        </card-wrapper>
        <card-wrapper id="faq" cardTitle="Frequently Asked Questions">
          <frequently-asked-questions />
        </card-wrapper>
      </div>
      <footer-component></footer-component>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import FooterComponent from "@/components/landing/FooterComponent.vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";

import FrequentlyAskedQuestions from "@/components/landing/FrequentlyAskedQuestions.vue";
import ContactUs from "@/components/landing/ContactUs.vue";

// const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

defineComponent({
  name: "FeaturesPage",
});

useHead({
  title: "Features - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content: "Explore the features of Slo-Pitch Central. Unleash your league or tournament here. We offer a free platform for scheduling games and tracking standings.",
    },
    {
      name: "keywords",
      content: "Slo-Pitch Central, Features, Leagues, Tournaments, Scheduling, Standings",
    },
    {
      name: "og:title",
      content: "Features - Slo-Pitch Central",
    },
    {
      name: "og:image",
      content: "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/features",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
  ],
});

// onMounted(() => {
//     getLeaguesAndTournaments();
// });

// onIonViewWillEnter(() => {
//     getLeaguesAndTournaments()
// })

// const getLeaguesAndTournaments = async () => {
//     const allLeagues = await api.get(`/api/all-leagues`);
//     console.log('allLeagues', allLeagues)
//     leagues.value = allLeagues.data.events;
//     const allTournaments = await api.get(`/api/all-tournaments`);
//     tournaments.value = allTournaments.data.events;
// }
</script>

<style scoped>
.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

ion-card-title {
  font-size: 18px;
}
</style>
