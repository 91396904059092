<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Frequently Asked Questions</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="main-content">
        <card-wrapper id="faq" cardTitle="Frequently Asked Questions">
          <frequently-asked-questions />
        </card-wrapper>
        <card-wrapper
          cardTitle="Contact Us"
          id="contact"
          cardSubtitle="Send us a message and we'll respond ASAP."
        >
          <contact-us />
        </card-wrapper>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
} from "@ionic/vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";

import FrequentlyAskedQuestions from "@/components/landing/FrequentlyAskedQuestions.vue";
import ContactUs from "@/components/landing/ContactUs.vue";

// const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

defineComponent({
  name: "FrequentlyAskedQuestionsPage",
});

useHead({
  title: "Frequently Asked Questions - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content: "Get answers to frequently asked questions about Slo-Pitch Central, our platform, and how we can help you host your schedules and standings.",
    },
    {
      name: "keywords",
      content: "FAQ, Slo-Pitch Central, Schedules, Standings, Hosting, Platform",
    },
    {
      name: "og:title",
      content: "Frequently Asked Questions - Slo-Pitch Central",
    },
    {
      name: "og:description",
      content: "Get answers to frequently asked questions about Slo-Pitch Central, our platform, and how we can help you host your schedules and standings.",
    },
    {
      name: "og:image",
      content: "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/faq",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
  ],
});

// onMounted(() => {
//     getLeaguesAndTournaments();
// });

// onIonViewWillEnter(() => {
//     getLeaguesAndTournaments()
// })

// const getLeaguesAndTournaments = async () => {
//     const allLeagues = await api.get(`/api/all-leagues`);
//     console.log('allLeagues', allLeagues)
//     leagues.value = allLeagues.data.events;
//     const allTournaments = await api.get(`/api/all-tournaments`);
//     tournaments.value = allTournaments.data.events;
// }
</script>

<style scoped>
.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

ion-card-title {
  font-size: 18px;
}
</style>
