<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Major Events</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="main-content">
        <ion-row style="max-width: 500px; margin: 0 auto; margin-top: 20px;">
            <ion-col class="ion-text-center">
                <a href="https://slo-pitch.com" target="_blank">
                    <img
                        src="https://slo-pitch.com/static/media/SPNLogo2019-White.7f71accc.png"
                        style="height: 100px; margin: 0 auto;"
                        alt="SPN Logo"
                    />
                </a>
            </ion-col>
            <ion-col class="ion-text-center">
                <a href="https://playslopitch.com" target="_blank">
                    <img
                        src="https://cdn.blacktiecollab.com/playslopitch.com/PLAYSLOPITCH%20Grunge%20hoodie%20logo%20with%20paint%20Left%20Chest%20copy.png"
                        style="width: 100%; margin: 20px auto 0;"
                        alt="Play Slo Pitch Logo"
                    />
                </a>
            </ion-col>
            <ion-col class="ion-text-center">
                <a href="https://playslopitch.com" target="_blank">
                    <img
                        src="https://cdn.blacktiecollab.com/playslopitch.com/spo-logo.png"
                        style="height: 100px; margin: 0 auto;"
                        alt="SPO Logo"
                    />
                </a>
            </ion-col>
        </ion-row>
        <card-wrapper
          id="find"
          cardTitle="Major Events"
          cardSubtitle="This list is all of our major events that we've hosted on the platform."
        >
            <ion-segment mode="md" scrollable :value="selectedEventType" @ionChange="handleSegmentChange">
                <ion-segment-button v-for="eventType in eventTypes" :key="eventType.slug" :value="eventType.slug">
                <div style="height: 60px; display: flex; align-items: center;">
                    <img :src="eventType.img" style="max-width: 60px; max-height: 60px;" alt="">
                </div>
                <ion-label>{{ eventType.name }}</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-row class="ion-no-padding">
                <template v-for="(event, index) in filteredEvents" :key="event.id">
                    <ion-col size-sm="4" size="12">
                        <div 
                            class="custom-card" 
                            @click="navigateToEvent(event)"
                        >
                            <div class="card-content">
                            <div class="text-content">
                                <h2>{{ event.name }}</h2>
                                <p>{{ event.city }}</p>
                                <p>{{ formatDate(event.event_start_date) }}</p>
                                <!-- Add more text content here if needed -->
                            </div>
                            <div class="arrow-container">
                                <ion-icon :icon="chevronForward" />
                            </div>
                            </div>
                        </div>
                    </ion-col>
                    <ion-col size="12" v-if="(index + 1) % 6 === 0">
                        <div :id="`majorEvents${Math.floor(index / 6)}`"></div>
                    </ion-col>
                </template>
            </ion-row>
        </card-wrapper>
        <card-wrapper
          id="find"
          cardTitle="Find an Event by Name"
          cardSubtitle="Enter the name you were given below to access the event."
        >
          <league-event-name-search />
        </card-wrapper>
        <!-- <ion-row>
          <ion-col>
            <div id="eventDirectory1"></div>
          </ion-col>
        </ion-row>
        <card-wrapper
          id="find"
          cardTitle="Find an Event by Shortcode"
          cardSubtitle="Enter the shortcode you were given below to access the event."
        >
          <league-search
            @eventFound="router.push(`/${$event.type}/${$event.slug}`)"
          />
        </card-wrapper> -->
        <ion-row>
          <ion-col>
            <div id="eventDirectory2"></div>
          </ion-col>
        </ion-row>
        <!-- <card-wrapper
          cardTitle="Featured Events"
          id="featured"
          cardSubtitle="Some of our most popularly viewed events."
        >
          <featured-events />
        </card-wrapper> -->
        <ion-row>
          <ion-col>
            <div id="eventDirectory3"></div>
          </ion-col>
        </ion-row>
        <!-- <card-wrapper
          id="find"
          cardTitle="League Directory"
          cardSubtitle="This directory includes all public leagues that are hosted on the Slo-Pitch Central platform."
        >
          <ion-row class="ion-no-padding">
            <ion-col
              size-sm="4"
              size="12"
              v-for="league of leagues"
              :key="league.id"
            >
              <ion-card class="ion-no-margin">
                <ion-card-header>
                  <ion-card-title>{{ league.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    size="small"
                    expand="full"
                    :router-link="`/league/${league.slug}/schedule`"
                  >
                    Go Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </card-wrapper> -->
        <ion-row>
          <ion-col>
            <div id="eventDirectory4"></div>
          </ion-col>
        </ion-row>
        <!-- <card-wrapper
          id="find"
          cardTitle="Tournament Directory"
          cardSubtitle="This directory includes all public tournaments that are hosted on the Slo-Pitch Central platform."
        >
          <ion-row class="ion-no-padding">
            <ion-col
              size-sm="4"
              size="12"
              v-for="tournament of tournaments"
              :key="tournament.id"
            >
              <ion-card class="ion-no-margin">
                <ion-card-header>
                  <ion-card-title>{{ tournament.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    size="small"
                    expand="full"
                    :router-link="`/tournament/${tournament.slug}/schedule`"
                  >
                    Go Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </card-wrapper> -->
        <ion-row>
          <ion-col>
            <div id="eventDirectory5"></div>
          </ion-col>
        </ion-row>
        <footer-component> </footer-component>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useRoute } from "vue-router"; // Add this import
import { useHead } from "@unhead/vue";
import {
  IonPage,
  IonRow,
  IonCol,
  onIonViewWillEnter,
  IonContent,
//   IonCard,
//   IonCardContent,
//   IonButton,
//   IonCardHeader,
//   IonCardTitle,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
} from "@ionic/vue";
import { chevronForward } from 'ionicons/icons';
import { format, parseISO } from 'date-fns'; // Make sure to import parseISO
const route = useRoute(); 

import { useRouter } from "vue-router";

import SitewideNotice from "@/components/header/SiteNotice.vue";

import FooterComponent from "@/components/landing/FooterComponent.vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
// import LeagueSearch from "@/components/landing/LeagueSearch.vue";
import LeagueEventNameSearch from "../components/landing/LeagueEventNameSearch.vue";
import { api } from "@/utils/axios";
// import FeaturedEvents from "@/components/landing/FeaturedEvents.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

const majorEvents = ref([]);
const selectedEventType = ref('2024-super-series-mens');
// const tournaments = ref([]);
const router = useRouter();

// const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

defineComponent({
  name: "EventDirectoryPage",
});

useHead({
  title: "Major Events - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content:
        "All Play-Slopitch, Slo-Pitch National and Slo-Pitch Ontario Major Events.",
    },
    {
      name: "keywords",
      content: "Slo-Pitch, Leagues, Tournaments, Events, Directory",
    },
    {
      name: "og:title",
      content: "Major Events - Slo-Pitch Central",
    },
    {
      name: "og:description",
      content:
        "Explore various leagues and tournaments hosted on Slo-Pitch Central. Find the perfect event for you and your team.",
    },
    {
      name: "og:image",
      content:
        "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/major-events",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
  ],
});


const eventTypes = [
  { slug: '2024-super-series-mens', name: 'Super Series Mens', img: 'https://cms-playslopitch.onrender.com/media/SPO%20SuperSeries%20logo.png' },
  { slug: '2024-super-series-womens', name: 'Super Series Womens', img: 'https://cms-playslopitch.onrender.com/media/SPO%20SuperSeries%20logo.png' },
  { slug: '2024-senior-circuit', name: 'Senior Circuit', img: 'https://cms-playslopitch.onrender.com/media/SPO%20Senior%20Circuit%20logo.png' },
  { slug: '2024-spo-provincial', name: 'Provincials', img: 'https://cms-playslopitch.onrender.com/media/Prov%20Championship%20LOGO.png' },
  { slug: 'grand-slam', name: 'Grand Slam Series', img: 'https://cms-playslopitch.onrender.com/media/2024%20Grand%20Slam%20Logo.png' },
  { slug: '2024-eliminations', name: 'Eliminations', img: 'https://cms-playslopitch.onrender.com/media/SPO%20Eliminations%20logo.png' },
  { slug: 'barrie-nationals', name: 'Nationals', img: 'https://cms-playslopitch.onrender.com/media/SPN%20National%20Championships%20logo.png' },
 
]
  // Ad


const filteredEvents = computed(() => {
  if (!selectedEventType.value || majorEvents.value.length === 0) {
    return majorEvents.value;
  }
  return majorEvents.value.filter(event => event.slug.startsWith(selectedEventType.value));
});

const updateSelectedEventType = (eventsParam) => {
  if (eventsParam && eventTypes.some(et => et.slug === eventsParam)) {
    selectedEventType.value = eventsParam;
  }
};

const handleSegmentChange = (event) => {
  selectedEventType.value = event.detail.value;
};

onMounted(() => {
  getMajorEvents();
  updateSelectedEventType(route.query.events);
});

onIonViewWillEnter(() => {
  getMajorEvents();
  updateSelectedEventType(route.query.events);
});

watch(() => route.query.events, (newEventsParam) => {
  updateSelectedEventType(newEventsParam);
});


const formatDate = (dateString) => {
  if (!dateString) return 'TBA';
  const date = parseISO(dateString);
  return format(date, 'MMMM d, yyyy'); // This will format the date as "Month Day, Year"
};


const getMajorEvents = async () => {
    const allMajorEvents = await api.get(`/custom-events/all-major-events`);
    
    majorEvents.value = allMajorEvents.data;
    
}

const navigateToEvent = (event) => {
  router.push(`/${event.type}/${event.slug}/schedule`);
};


// const getLeaguesAndTournaments = async () => {
//   const allLeagues = await api.get(`/api/all-leagues`);
//   console.log("allLeagues", allLeagues);
//   leagues.value = allLeagues.data.events;
//   const allTournaments = await api.get(`/api/all-tournaments`);
//   tournaments.value = allTournaments.data.events;
// };
</script>

<style scoped>
.custom-card {
  
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #7438e9;
}

.custom-card:hover {
  background: #5d25cc;
}

.card-content {
  display: flex;
  align-items: stretch;
  min-height: 120px;
}

.text-content {
  flex-grow: 1;
  padding: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content h2 {
  margin: 0 0 4px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.text-content p {
  margin: 0;
  font-size: 14px;
  text-align: left;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Fixed width */
  flex-shrink: 0; /* Prevent shrinking */
  background-color: rgba(0, 0, 0, 0.4);
}

.arrow-container ion-icon {
  font-size: 24px;
  color: white;
}

.search-button {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .search-button {
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .search-button {
    font-size: 12px;
  }
}
</style>