<template>
    <base-modal modal-title="Activate Registration System" @close="close(false)">
        <template #headerStart>
            <ion-button @click="close(false)"> Close </ion-button>
        </template>
        <div v-if="activateRegistrationRef === false">
            <h1
                style="
                color: var(--ion-color-dark);
                text-align: center;
                text-transform: uppercase;
                "
            >
                <span style="background-color: var(--ion-color-primary); padding: 0 10px">
                    Registration Made Easy
                </span>
            </h1>
            <p>It's very simple to handle registration through our platform. But before we do, we want to make sure you understand everything.</p>

            <p>1. You can set up accepting Credit Card through our Easy-Connect Stripe setup. Through this, you will be able to accept automatic payments, and payments will be tracked automatically in Slo-Pitch Central.</p>
            <p>2. You can set up accepting E-transfers or external payment methods, but we can't track when invoices are paid, so you will need to track this information manually.</p>
            <p>3. We handle customer service for you! If your customers have any issues, you can have them email us at payments@slopitchcentral.com to get it sorted!</p>
            <p>If you're ready to go, press the Activate button below to get started!</p>
            <br>
            <ion-button @click="activationRegistration">Activate Registration</ion-button>
        </div>
        <div v-if="activateRegistrationRef === true">
            <div>
                <h1
                    style="
                    color: var(--ion-color-dark);
                    text-align: center;
                    text-transform: uppercase;
                    "
                >
                    <span style="background-color: var(--ion-color-primary); padding: 0 10px">
                        1. Set Up Credit Card Account
                    </span>
                </h1>
                <p>You will be redirected when you click the below link. You will have to fill out a small form, which will set up your account where you can accept payment.</p>
                <p>Once you have completed the form, you will be redirected back here.</p>
                <div v-if="isStripeAccountActivated">
                    <ion-button color="success">Connected!</ion-button>
                </div>
                <!-- stripe is fully confirmed -->
                <div v-if="leagueStore.league.stripe_account_id && leagueStore.league.stripe_account_confirmed !== 1">
                    <ion-button 
                        color="secondary"
                        @click="activateStripeAccount"
                    >Complete Stripe Account</ion-button>
                </div>
                <div v-if="!leagueStore.league.stripe_account_id">
                    <ion-button 
                        color="secondary"
                        @click="activateStripeAccount"
                    >Activate Stripe Account</ion-button>
                </div>
                

            </div>
            <div style="margin-top: 90px;">
                <h1
                    style="
                    color: var(--ion-color-dark);
                    text-align: center;
                    text-transform: uppercase;
                    "
                >
                    <span style="background-color: var(--ion-color-primary); padding: 0 10px">
                        2. Provide EMT Details (Optional)
                    </span>
                </h1>
                <ion-item>
                    <ion-label position="stacked">What email do you want to accept EMTs at?</ion-label>
                    <ion-input v-model="acceptEMTAt"></ion-input>
                </ion-item>
            </div>
            <div style="margin-top: 90px;">
                <h1
                    style="
                    color: var(--ion-color-dark);
                    text-align: center;
                    text-transform: uppercase;
                    "
                >
                    <span style="background-color: var(--ion-color-primary); padding: 0 10px">
                        3. Provide Cash Details (Optional)
                    </span>
                </h1>
                <ion-item>
                    
                    <ion-checkbox v-model="acceptsCashDayOf"/>
                    <ion-label>Do you accept cash the day of the event?</ion-label>
                </ion-item>
            </div>
            <div style="margin-top: 40px; text-align: center;">
                <ion-button @click="saveYourRegistrationDetails">Save Your Registration Details</ion-button>
            </div>
        </div>
    </base-modal>
</template>

<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { modalController, IonButton, IonItem, IonLabel, IonInput, IonCheckbox } from "@ionic/vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { showToast } from "@/utils/useIonicComponents";
const router = useRouter();
const route = useRoute();
const leagueStore = useLeagueStore();

import { api } from "@/utils/axios";

const activateRegistrationRef = ref(leagueStore.league.activate_registration === 1 ? true : false);
const isStripeAccountActivated = ref(leagueStore.league.stripe_account_confirmed === 1 ? true : false);
const acceptEMTAt = ref(leagueStore.league.emt_email ? leagueStore.league.emt_email : '');
const acceptsCashDayOf = ref(leagueStore.league.accepts_cash_day_of === 1 ? true : false);

async function close(success = false) {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}

const activationRegistration = () => {
    activateRegistrationRef.value = true

    api.post('/api/registration/activate-registration', {
        league_slug: leagueStore.league.slug
    }).then((response) => {
        console.log(response)
    })
}

onMounted(() => {
    checkForRegistrationDetails()
})

const checkForRegistrationDetails = () => {
    api.get(`/api/registration/check-for-registration-details/${leagueStore.league.slug}`).then((response) => {
        console.log(response)
        if(response.data.setupComplete === true) {
            isStripeAccountActivated.value = true
        }
    })
}

const activateStripeAccount = async () => {

    api.post('/api/registration/activate-stripe-account', {
        type: leagueStore.league.type,    
        league_slug: leagueStore.league.slug,
    }).then((response) => {
        console.log(response)
        // Assuming 'response' is the object containing the AccountLink URL received from your backend
        const accountLinkUrl = response.data.url;

        // Redirect the user to the AccountLink URL
        window.location.href = accountLinkUrl;

    })

}

const saveYourRegistrationDetails = () => {
    api.post('/api/registration/save-registration-details', {
        league_slug: leagueStore.league.slug,
        emt_email: acceptEMTAt.value,
        accepts_cash_day_of: acceptsCashDayOf.value
    }).then(async () => {
        await leagueStore.reloadEverything(leagueStore.league.slug);
        showToast(
            "Registration details officially set!",
            "success",
            3000
        );
        close()
    })
}

</script>
