<template>
  <base-modal modal-title="Edit Rules" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <QuillEditor
      theme="snow"
      toolbar="full"
      :modules="modules"
      v-model:content="leagueRules"
      :ref="editor"
      @ready="setContent($event)"
    />
    <template #footer>
      <ion-button expand="full" @click="saveRules">Save Rules</ion-button>
    </template>
  </base-modal>
</template>
<script setup>
import { defineComponent, ref, onMounted } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useRouter, useRoute } from "vue-router";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import { isEmpty } from "lodash";

defineComponent({
  name: "RulesModal",
});
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();
const preferenceStore = usePreferenceStore();

router.replace({
  query: {
    ...route.query,
    open_modal: "EditRules",
  },
});
const saveInterval = ref();
const leagueRules = ref();
const editor = ref();

onMounted(async () => {
  saveInterval.value = setInterval(localSave, 2500);
});
function localSave() {
  // console.log("autosaved", announcement.value);
  preferenceStore.setEditRules(leagueRules.value);
}

const modules = [
  {
    name: "blotFormatter",
    module: BlotFormatter,
  },
  {
    name: "imageUploader",
    module: ImageUploader,
    options: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const access_key = "6720c8ce-1e73-4f38-be0c52815ece-593f-4260";
          const base_url = "https://la.storage.bunnycdn.com";
          const storageName = "blacktiecollab-la";
          const path = "/slopitchcentral/Rules_Media";
          const file_name = `${leagueStore.league.id}-${file.name}`;
          const cdn_url = "cdn.blacktiecollab.com";

          fetch(`${base_url}/${storageName}${path}/${file_name}`, {
            method: "PUT",
            headers: {
              AccessKey: `${access_key}`,
            },
            body: file,
          })
            .then(() => {
              resolve(`https://${cdn_url}${path}/${file_name}`);
            })
            .catch((err) => {
              reject("Upload failed");
              console.log(err);
            });
        });
      },
    },
  },
];

function setContent(event) {
  if (!isEmpty(preferenceStore.getUserEditRules)) {
    event.setContents(preferenceStore.getUserEditRules, "api");
  } else {
    event.setContents(JSON.parse(leagueStore.league.rules), "api");
  }
}

async function saveRules() {
  if (JSON.stringify(leagueRules.value).length > 0) {
    await saveRulesRequest();
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

async function saveRulesRequest() {
  const loading = await showLoading("Saving Rules. Please Wait...");
  const result = await api
    .patch(`/api/events/${leagueStore.league.id}/rules`, {
      rules: JSON.stringify(leagueRules.value),
    })
    .catch((err) => {
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
      loading.dismiss();
    });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    leagueRules.value = {};
    await close(true);
    showToast("Rules successfully Added.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close(success = false) {
  clearInterval(saveInterval.value);
  localSave();
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}
</script>
