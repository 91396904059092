<template>
    <div>
        <ion-card>
            <ion-card-content>
                <ion-row>
                    <ion-col size-sm="4" size="12">
                        <img
                            src="https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png"
                            style="max-width: 300px; width: 70%"
                            alt="Slo-Pitch Central Logo"
                        />
                    </ion-col>
                    <ion-col size-sm="4" size="12" class="ion-text-left">
                        <p><b>DIRECTORY</b></p>
                        <router-link
                            v-for="(link, key) in links"
                            :key="key"
                            class="footer-link"
                            :to="link.path">
                            {{ link.name}}
                        </router-link>
                    </ion-col>
                    <ion-col size-sm="4" size="12">
                        <p><b>FREE TOOLS</b></p>
                        <router-link
                            v-for="(link, key) in links2"
                            :key="key"
                            class="footer-link"
                            :to="link.path">
                            {{ link.name}}
                        </router-link>
                    </ion-col>
                </ion-row>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script setup>
import { defineComponent } from 'vue'

import { IonRow, IonCardContent, IonCol } from '@ionic/vue'

import {
    IonCard
} from '@ionic/vue'

const links = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'Major Events',
        path: '/major-events'
    },
    {
        name: 'Event Directory',
        path: '/event-directory'
    },
    {
        name: 'Features',
        path: '/features'
    },
    {
        name: 'Blogs',
        path: '/posts'
    },
    {
        name: 'Contact',
        path: '/contact-us'
    }
]

const links2 = [
    {
        name: 'Ballpark Directory',
        path: '/ballparks'
    },
    {
        name: 'League/Tournament Budget Generator',
        path: '/budget-generator'
    }
    // {
    //     name: 'Team Directory',
    //     path: '/team-directory'
    // },
    // {
    //     name: 'Tournament Directory',
    //     path: '/tournament-directory'
    // },
    // {
    //     name: 'League Directory',
    //     path: '/league-directory'
    // }
]

defineComponent({
    name: 'FooterComponent'
})
</script>

<style scoped>
.footer-link {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 5px 0;
}

.footer-link:hover {
    text-decoration: underline;
}
</style>