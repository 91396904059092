<template>
    <page-wrapper>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Demos</ion-title>
        </ion-toolbar>
      </ion-header>
      <card-wrapper
        cardTitle="Demo Videos"
        id="featured"
        cardSubtitle="Check out our demo videos to see how we can help you improve your game."
      >
        <ion-row class="ion-no-padding">
          <template v-for="(post, index) in posts" :key="post.id">
            <ion-col size-sm="6" size="12">
              <ion-card class="ion-no-padding ion-no-margin">
                <ion-card-header>
                  <ion-card-title style="font-size: 22px; text-transform: uppercase; text-align: center;">
                    {{ post?.title }}
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    size="small"
                    expand="full"
                    target="_blank"
                    :href="`${post.url}`"
                  >
                    View Now
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <template v-if="(index + 1) % 4 === 0">
              <ion-col :key="'ad-' + index" size="12">
                <div id="adDemos"></div>
              </ion-col>
            </template>
          </template>
        </ion-row>
      </card-wrapper>
      <footer-component></footer-component>
    </page-wrapper>
  </template>
  
  <script setup>
  import { defineComponent, ref } from "vue";
  import CardWrapper from "@/components/layouts/CardWrapper.vue";
  import SitewideNotice from "@/components/header/SiteNotice.vue";
  import FooterComponent from "@/components/landing/FooterComponent.vue";
  import PageWrapper from "@/components/layouts/PageWrapper.vue";
//   import axios from "axios";
  import {
    IonRow,
    IonCol,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonButton,
    // onIonViewWillEnter,
  } from "@ionic/vue";
//   import he from "he";
  // import NewAdComponent from "@/components/ads/NewAdComponent.vue";
  
  defineComponent({
    name: "PostsPage",
  });
  
  let posts = ref([
    {
      title: "How to Find an Event",
      description: "How to Find an Event on SloPitchCentral.com",
      url: 'https://app.storylane.io/share/4oyb2dxbooql'
    },
    {
      title: "How to import your schedule",
      url: 'https://app.storylane.io/share/3hlarlwxphxg'
    },
    {
      title: 'How to change team shortcodes',
      url: 'https://app.storylane.io/share/rmomgjdukw2q'
    },
    {
      title: 'How to Change Score Reporting Permissions',
      url: 'https://app.storylane.io/share/myqzpuht0ev7'
    },
    {
      title: 'How to Register or Login',
      url: 'https://app.storylane.io/share/0xzuluoqpupl'
    },
    {
      title: 'Understanding your convenor dashboard',
      url: 'https://app.storylane.io/share/ntuw0ikxpxvz'
    },
    {
      title: 'How to create an event with schedule',
      url: 'https://app.storylane.io/share/bxp4btcndhzz'
    },
    {
      title: 'How to report a score',
      url: 'https://app.storylane.io/share/ndiblwfyrbe2'
    }
  ]);
  

  </script>
  
  <style>
  .sl-heading-text {
    max-width: 53%;
  }
  @media (max-width: 1024px) {
    .sl-heading-text {
      max-width: 90%;
    }
  }
</style>