<template>
  <tr style="height: 3rem; border-bottom: 0px solid;" :class="filteredTeam">
    <!-- <td>
      <b style="padding: 0 0.5rem; font-size: 10px;">{{ props.team_key + 1 }}</b>
    </td> -->
    <td
      :style="[
        {
          borderColor: props.team?.color
            ? props.team?.color
            : uniqolor(props.team.name).color,
        },
      ]"
      style="
        border-right: 0px;
        text-align: left;
        text-transform: uppercase;
        font-weight: 500;
        padding-left: 5px;
        border-left-style: solid;
        border-width: 0.75rem;
      "
    >
      <span style="padding-left: 5px">
        {{ props.team.name }}
        <span
          style="color: var(--ion-color-medium)"
          v-if="preferenceStore.showTeamIDs"
          >| {{ props.team.id }}</span
        >
      </span>
    </td>
    <td class="fixed-width font-size">
      {{
        parseInt(props.team.wins) +
        parseInt(props.team.losses) +
        parseInt(props.team.ties)
      }}
    </td>
    <td class="fixed-width font-size">
      {{ props.team.wins }}
    </td>
    <td class="fixed-width font-size">
      {{ props.team.losses }}
    </td>
    <td class="fixed-width font-size">
      {{ props.team.ties }}
    </td>
    <td class="fixed-width font-size">{{ props.team.points || 0 }}</td>
    <td class="fixed-width font-size">
      {{ props.team.runs_for }}
    </td>
    <td class="fixed-width font-size">
      {{ props.team.runs_against }}
    </td>
    <td class="fixed-width font-size">
      <span :style="{ 'margin-right': authStore.isConvenor ? '' : '1rem' }">
        {{ props.team.plus_minus }}
      </span>
    </td>
    <td v-if="authStore.isConvenor">
      <ion-button
        style="margin: 0; padding: 0"
        fill="clear"
        size="small"
        @click="openEditModal"
      >
        <ion-icon slot="icon-only" :icon="createOutline"></ion-icon>
      </ion-button>
    </td>
  </tr>
</template>
<script setup>
import { defineComponent, defineProps, computed } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { createOutline } from "ionicons/icons";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useAuthStore } from "@/stores/authStore";
import EditStandingsModal from "@/components/standings/EditStandingsModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";
import { useGameStore } from "@/stores/gameStore";
import uniqolor from "uniqolor";
import { useRoute } from "vue-router";

const authStore = useAuthStore();
const gameStore = useGameStore();
const preferenceStore = usePreferenceStore();

defineComponent({
  name: "StandingsRows",
});
const props = defineProps({ team: Object, team_key: Number, mode: String });
const route = useRoute();

async function openEditModal() {
  const editStandingsModalData = {
    team: props.team,
    leagueSlug: route.params.leagueSlug,
  };
  localStorage.setItem(
    "editStandingsModalData",
    JSON.stringify(editStandingsModalData)
  );
  await openModal(EditStandingsModal, "editStandingsModal", {
    team: props.team,
    mode: props.mode,
  });
}
const filteredTeam = computed(() => {
  if (gameStore.gameFilters.team.includes(props.team.id)) {
    return "filtered-teams";
  }
  return "";
});
</script>
<style scoped>
tr:nth-child(odd) {
  background-color: var(--ion-color-light);
}
.filtered-teams {
  border-color: #7d38f0;
  border-width: 4px;
  border-style: solid;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
}

.font-size {
  font-size: 1.2rem;
  font-weight: 700;
}
</style>
