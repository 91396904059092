<template>
  <span>
    <ion-toolbar v-if="showDesktopMode" color="light" style="height: 48px">
      <ion-buttons v-if="gameStore.selectedGames.length === 0">
        <ion-button
          color="dark"
          fill="outline"
          class="selector-button"
          @click="toggleGamesLayout"
        >
          Toggle
          {{
            preferenceStore.getGamesLayout === "grid"
              ? "Table"
              : preferenceStore.getGamesLayout === "table"
              ? "Brackets"
              : "Grid"
          }}
          View
        </ion-button>
        <ion-button
          color="primary"
          fill="outline"
          class="selector-button"
          @click="openFilters"
        >
          <ion-icon :icon="filterOutline"></ion-icon>&nbsp;&nbsp; Filter
          Schedule
        </ion-button>
        <ion-button
          color="primary"
          fill="outline"
          class="selector-button"
          @click="openSortModal"
        >
          <ion-icon :icon="funnelOutline"></ion-icon>&nbsp;&nbsp; Sort Schedule
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="start" v-if="gameStore.selectedGames.length > 0">
        <div class="games-selected">
          {{ gameStore.selectedGames.length }} Games Selected
        </div>
        <!-- <ion-button
          color="dark"
          fill="outline"
          class="selector-button"
          :disabled="true"
        >
          {{ gameStore.selectedGames.length }} Games Selected
        </ion-button> -->
        <ion-button
          class="selector-button"
          fill="outline"
          color="success"
          @click="openReportModal"
        >
          <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;&nbsp;Submit
          Scores
        </ion-button>
        <ion-button
          class="selector-button"
          fill="outline"
          color="primary"
          @click="openSelectedGamesModal('EditTeams')"
        >
          <ion-icon :icon="createOutline"></ion-icon>&nbsp;&nbsp;Edit Game
        </ion-button>
        <ion-button
          class="selector-button"
          fill="outline"
          color="secondary"
          @click="openSelectedGamesModal('Postpone')"
        >
          <ion-icon :icon="thunderstormOutline"></ion-icon>&nbsp;&nbsp;Postpone
        </ion-button>
        <ion-button
          class="selector-button"
          fill="outline"
          color="tertiary"
          @click="openSelectedGamesModal('Reschedule')"
        >
          <ion-icon :icon="calendarNumberOutline"></ion-icon
          >&nbsp;&nbsp;Reschedule
        </ion-button>
        <ion-button
          class="selector-button"
          color="warning"
          fill="outline"
          @click="resetScores()"
        >
          <ion-icon :icon="refreshCircleOutline"></ion-icon>&nbsp;&nbsp;Reset
          Scores
        </ion-button>
        <ion-button
          color="danger"
          fill="outline"
          class="selector-button"
          @click="openSelectedGamesModal('Delete')"
        >
          <ion-icon :icon="trashOutline"></ion-icon>&nbsp;&nbsp;Delete
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button
          fill="outline"
          class="selector-button"
          color="secondary"
          @click="toggleSelectGames"
          v-if="!gameStore.showSelectGames"
        >
          <ion-icon :icon="checkmarkDoneCircleOutline"></ion-icon
          >&nbsp;&nbsp;Select Games
        </ion-button>
        <ion-button
          fill="outline"
          class="selector-button"
          @click="toggleSelectGames"
          v-if="gameStore.showSelectGames"
        >
          Deselect All
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-modal :is-open="showModal" @did-dismiss="closeReportModal">
      <game-cards-popup
        :games="gameStore.selectedGames"
        @close="closeReportModal"
      >
      </game-cards-popup>
    </ion-modal>
  </span>
</template>

<script setup>
import { defineComponent, ref, onBeforeUnmount, defineEmits } from "vue";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
} from "@ionic/vue";
import { useGameStore } from "@/stores/gameStore";
import { showToast, openModal, showAlert } from "@/utils/useIonicComponents.js";
import { debounce } from "lodash";
import FilterModal from "@/components/schedule/filters/FilterModal.vue";
import SortModal from "@/components/schedule/sort-by/SortModal.vue";
import SelectedGameModal from "@/components/schedule/select-game/SelectedGameModal.vue";
import GameCardsPopup from "@/components/schedule/game-grid/GameCardsPopup.vue";
import { useAuthStore } from "@/stores/authStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import {
  // optionsOutline,
  checkmarkDoneCircleOutline,
  thunderstormOutline,
  calendarNumberOutline,
  refreshCircleOutline,
  trashOutline,
  createOutline,
  filterOutline,
  alertCircleOutline,
  // filterSharp,
  funnelOutline,
} from "ionicons/icons";
import { useRoute } from "vue-router";

const showModal = ref(false);
const authStore = useAuthStore();

function openReportModal() {
  if (authStore.isLoggedIn) {
    showModal.value = true;
  }
}

async function openSortModal() {
  await openModal(SortModal, "sortModal");
}

function closeReportModal() {
  showModal.value = false;
  // reportGames.showReportButton = false;
  // console.log('props.game', props.game)
}

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
defineComponent({
  name: "SelectGamesToolbar",
});
const emit = defineEmits(["resetScores"]);
const gameStore = useGameStore();
const route = useRoute();
const preferenceStore = usePreferenceStore();

function toggleSelectGames() {
  gameStore.showSelectGames = !gameStore.showSelectGames;
  if (gameStore.showSelectGames) {
    showToast("Select the Games to Reschedule or Postpone", "primary");
  } else {
    gameStore.selectedGames = [];
    gameStore.saveSelectGame();
  }
}

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

window.addEventListener("resize", debounce(resizeHandler, 1000));

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});

async function openSelectedGamesModal(choice) {
  const selectedGamesModalData = {
    choice,
    leagueSlug: route.params.leagueSlug,
  };
  localStorage.setItem(
    "selectedGamesModalData",
    JSON.stringify(selectedGamesModalData)
  );
  await openModal(SelectedGameModal, "selectModal", { choice });
}

async function resetScores() {
  await showAlert({
    header: "The scores for the selected games will be reset!",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "Reset",
        role: "confirm",
        handler: async () => {
          await gameStore.resetScores();
          emit("resetScores");
        },
      },
    ],
  });
}

function toggleGamesLayout() {
  const currentLayout = preferenceStore.getGamesLayout;
  const nextLayout =
    currentLayout === "grid"
      ? "table"
      : currentLayout === "table"
      ? "brackets"
      : "grid";
  preferenceStore.setGamesLayout(nextLayout);
}

async function openFilters() {
  await openModal(FilterModal, "filterModal");
}
</script>

<style scoped>
.selector-button {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  --padding-start: 5px;
  --padding-end: 5px;
}

.games-selected {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px;
  color: var(--ion-color-primary);
}
</style>
