<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Leagues</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="header-text">
        <div class="text-center" style="padding: 20px">
          <h1 style="text-transform: uppercase">
            Slo-Pitch Leagues in {{ city }}
          </h1>
          <p>This is a comprehensive list of the Slo-Pitch leagues hosted on Slo-Pitch Central based on the city they selected when creating the league.</p>
        </div>
      </div>
      <div class="header-text" style="margin-top: 2rem">
        <!-- <ion-searchbar
          v-model="searchQuery"
          @ionChange="search"
          placeholder="Search for a league"
        >
        </ion-searchbar> -->
        <ion-spinner v-if="loading"></ion-spinner>

        <div v-if="leagues.length > 0" class="leagues-grid">
          <template v-for="(league, index) in leagues" :key="league.id">
            <ion-card class="league-card">
              <ion-card-header>
                <ion-card-title>{{ league.name }}</ion-card-title>
                <ion-card-subtitle>{{ league.city }}</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <!-- Add more league details here -->
                <ion-button :href="`/league/${league.slug}/schedule`">View League Page</ion-button>
              </ion-card-content>
            </ion-card>

            <!-- Insert ad div after every 4 leagues -->
            <div v-if="(index + 1) % 4 === 0 || index === leagues.length - 1" class="ad-container" :id="`leaguesByCityPage${Math.floor(index / 4)}`"></div>
          </template>
        </div>
        <div v-else-if="!loading">
          No leagues found in {{ city }}.
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import { api } from "@/utils/axios";
import { useHead } from "@unhead/vue";

import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner, IonContent, IonPage, IonButton, IonTitle, IonButtons, IonToolbar, IonHeader, IonMenuButton, IonCardContent } from "@ionic/vue";

const route = useRoute();
const city = ref(route.params.city); // Get city slug from route parameters

useHead({
  title: `Slo-Pitch Leagues in ${city.value}, Canada | Slo-Pitch Central`,
  meta: [
    {
      name: "description",
      content: `Explore the most comprehensive directory of Slo-Pitch leagues in ${city.value} on Slo-Pitch Central. Find the perfect league for your next game.`,
    },
  ],
});

const searchQuery = ref("");
const leagues = ref([]);
const loading = ref(false);
const currentPage = ref(0);
const moreToLoad = ref(true);

const loadMore = async (event) => {
  if (!moreToLoad.value) {
    if (event) event.target.complete();
    return;
  }

  loading.value = true;
  try {
    const response = await api.get(
      `/api/leagues-by-city?city=${city.value}&search=${searchQuery.value}`
    );
    if (response.data.leagues && response.data.leagues.length > 0) {
      leagues.value = [...leagues.value, ...response.data.leagues];
      currentPage.value += 1;
    } else {
      moreToLoad.value = false;
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (event) event.target.complete();
    loading.value = false;
  }
};

onMounted(() => {
  loadMore();
});

// const search = async () => {
//   loading.value = true;
//   leagues.value = [];
//   currentPage.value = 0;
//   moreToLoad.value = true;
//   await loadMore();
// };
</script>


<style scoped>
/* .landing-content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-around;
}
.landing-content-child {
  min-height: 10rem;
  min-width: 35rem;
} */

.header-area {
  width: 100%;
  display: block;
  position: relative !important;
}

/* .background {
  --background: url(https://cdn.blacktiecollab.com/slopitchcentral/images/background2.png) center center no-repeat;
  --background-size: cover;
} */
.header-image {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(125, 56, 240, 0.6) 80%,
    rgba(125, 56, 240, 0.9) 100%
  );
  text-align: center;
}
.header-text {
  max-width: 800px;
  margin: 0 auto;
}

.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.qa-wrapper {
  padding: 10px 0;
  line-height: 1.5;
}

.question {
  padding-bottom: 5px;
  font-weight: 700;
  color: #7d38f0;
}

ion-card-title {
  font-size: 18px;
}

@media only screen and (max-width: 1250px) {
  /* .landing-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .landing-content-child {
    width: 90%;
    min-width: 20rem;
    max-width: 35rem;
  } */
}

.tally-app {
  background: #121212 !important;
}
</style>
